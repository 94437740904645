import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListFlowItemId, getListFlowSubmittableItem } from './ui';
import { ThunkConfig } from '~/store/store';
import {
  createOrUpdateItem,
  postCreateParcel,
  postUploadImage,
  postValidateItem,
} from '~/api/listFlow';
import { ApiError, FieldError } from 'fetcher-session';
import { RailsItem } from '~/typings/services/rails/item';
import { postPublishItem } from '~/api/item';
import {
  getListFlowImages,
  uploadedPhotoToRails,
} from '~/store/listFlow/slices/photos';
import {
  getListFlowShipping,
  uploadedParcel,
} from '~/store/listFlow/slices/shipping';
import { fireListedItem } from '~/services/analytics/events/listFlow';
import { NextRouter } from 'next/dist/client/router';
import { linkEbayItem } from '~/api/ebay';
import { initializeFromItem } from '~/store/listFlow/initializationActions';

export const listFlowSaveDraft = createAsyncThunk<
  void,
  {
    router: NextRouter;
    onSuccess?: (item: RailsItem) => void;
    onError?: (err: ApiError) => void;
  },
  ThunkConfig<FieldError[] | undefined>
>(
  'listFlow/saveDraft',
  async (args, { dispatch, getState }) => {
    const needsToNavigate = !getListFlowItemId(getState());
    try {
      const images = getListFlowImages(getState());
      const shipping = getListFlowShipping(getState());

      for (let i = 0; i < images.length; i++) {
        const image = images[i];

        if (!image.railsId && image.url && image.file) {
          try {
            const blobUrlToFile = await fetch(image.url);
            const blob = await blobUrlToFile.blob();

            const railsImage = await postUploadImage(blob);

            if (railsImage) {
              dispatch(
                uploadedPhotoToRails({
                  image: railsImage,
                  index: i,
                }),
              );
            } else {
              throw new Error('Failed to upload image');
            }
          } catch (e) {
            // TODO: Handle image upload error
            console.error(e);
          }
        }
      }

      if (
        !shipping.useCategoryParcel &&
        shipping.customParcel &&
        !shipping.customParcel?.id
      ) {
        const parcel = await postCreateParcel(shipping.customParcel);
        dispatch(uploadedParcel({ railsParcel: parcel }));
      }

      // If you're using the category parcel but there IS no parcel. In this case, we want to use the
      // default dimensions.
      if (shipping.useCategoryParcel && !shipping.customParcel) {
        const parcel = await postCreateParcel(shipping.customParcel);
        dispatch(uploadedParcel({ railsParcel: parcel }));
      }

      const listFlowSubmittableItem = getListFlowSubmittableItem(getState());

      const item = await createOrUpdateItem(listFlowSubmittableItem);

      const state = getState();
      if (item.ebay?.id && state.listFlow.info.ebay.id !== item.ebay?.id) {
        await linkEbayItem(item.id, item.ebay.id);
      }

      dispatch(initializeFromItem(item));

      if (needsToNavigate) {
        await args.router.replace(`/items/${item.id}`, `/items/${item.id}`, {
          scroll: false,
          shallow: true,
        });
      }

      await args.onSuccess?.(item);
    } catch (e) {
      const err = e as ApiError;
      args.onError?.(err);
      throw err.errors;
    }
  },
  {
    // Don't let RTK change our error serializer
    serializeError: x => x,
  },
);

export const listFlowValidate = createAsyncThunk<
  void,
  {
    router: NextRouter;
    onSuccess?: (item: RailsItem) => void;
    onError?: (err: ApiError) => void;
  },
  ThunkConfig<FieldError[] | undefined>
>(
  'listFlow/validate',
  async (args, { dispatch, getState }) => {
    await dispatch(listFlowSaveDraft({ router: args?.router }));

    try {
      // Do publish
      const itemId = getListFlowItemId(getState());
      if (!itemId) {
        // Something is really wrong
        return;
      }

      const item = await postValidateItem(itemId);
      await args?.onSuccess?.(item);
    } catch (e) {
      const err = e as ApiError;
      args?.onError?.(err);
      throw err.errors;
    }
  },
  {
    // Don't let RTK change our error serializer
    serializeError: x => x,
  },
);

export const listFlowPublish = createAsyncThunk<
  void,
  {
    router: NextRouter;
    onSuccess?: (item: RailsItem) => void;
    onError?: (err: ApiError) => void;
  },
  ThunkConfig<FieldError[] | undefined>
>(
  'listFlow/publish',
  async (args, { dispatch, getState }) => {
    try {
      // Do publish
      const itemId = getListFlowItemId(getState());
      if (!itemId) {
        // Something is really wrong
        return;
      }

      const item = await postPublishItem(itemId);
      await fireListedItem(item, getState().listFlow);
      await args?.onSuccess?.(item);
    } catch (e) {
      const err = e as ApiError;
      args?.onError?.(err);
      throw err.errors;
    }
  },
  {
    // Don't let RTK change our error serializer
    serializeError: x => x,
  },
);
