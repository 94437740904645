import React from 'react';
import { qsStringify } from 'utils';
import { useModals } from '~/contexts/ModalsContext';

import { CustomLinkProps } from 'common-nextjs';

interface LoginLinkOptions {
  destination?: string;
  message?: string;

  /**
   * Applied to the "destination" param on the login page as a query string
   * Can be used to define "return actions," like once logged in, come back and do this.
   */
  returnParams?: Record<string, any>;
  skipOnboarding?: boolean;
  onSuccessfulLogin?: () => void;
}

type Props = CustomLinkProps & LoginLinkOptions;

export function generateLoginLink(options?: LoginLinkOptions) {
  return `/login${qsStringify(options)}`;
}

const LoginLink: React.FC<Props> = ({
  children,
  destination,
  message,
  onSuccessfulLogin,
  returnParams,
  skipOnboarding,
  ...props
}) => {
  const modals = useModals();
  // const router = useRouter();
  // if (router.route === '/login' || router.route === '/register') {
  //   return (
  //     <Link
  //       href="/login"
  //       rel="nofollow noindex"
  //       aria-label="Sign into your SidelineSwap Account"
  //       {...props}
  //     >
  //       {children}
  //     </Link>
  //   );
  // }

  return (
    <a
      {...props}
      href="#"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        modals.openModal('loginRegister', {
          onSuccessfulLogin,
          contextMessage: message,
          defaultView: 'login',
          skipOnboarding,
        });
      }}
      aria-label="Sign into your SidelineSwap Account"
    >
      {children}
    </a>
  );
};

export default LoginLink;
