import { format } from 'date-fns';
import { fetcher, makeFetcher } from 'fetcher-session';
import {
  RailsUserConfiguration,
  RailsV2Session,
  RailsVacation,
} from '~/typings/services/rails/session';
import { RailsReferralCode } from '~/typings/services/rails/user';
import { BaseUserSearchFilterResponse } from '~/typings/services/rails/userSearchFilters';

export const fetchReferralInfo = makeFetcher<RailsReferralCode>(
  () =>
    ({ uuid }) =>
      `/v1/users/${uuid}/referral_code`,
);

export const putAmbassadorApply = () =>
  fetcher<any>(({ uuid }) => `/v1/users/${uuid}/ambassador`, {
    method: 'put',
  });

export const fetchReferralCodeByName = (name: string) =>
  fetcher<RailsReferralCode>(`/v1/referral_codes/${name}`, {
    ignoreErrors: true,
  });

export const putRoles = (roles: string[]) =>
  fetcher(({ uuid }) => `/profile/v1/users/${uuid}/profile`, {
    method: 'put',
    body: {
      profile: {
        roles_ids: roles,
      },
    },
  });

export const putFavoriteSports = (categoryIds: string[]) =>
  fetcher(({ uuid }) => `/profile/v1/users/${uuid}/profile`, {
    method: 'put',
    body: {
      profile: {
        sport_ids: categoryIds,
      },
    },
  });

export const putUploadAvatar = (file: File) => {
  const data = new FormData();
  data.append('avatar', file);

  return fetcher(({ uuid }) => `/v1/users/${uuid}/avatar`, {
    method: 'put',
    blob: true,
    body: data,
  });
};

export const putUploadCoverPhoto = (file: File) => {
  const data = new FormData();
  data.append('cover', file);

  return fetcher(({ uuid }) => `/v1/users/${uuid}/cover`, {
    method: 'put',
    blob: true,
    body: data,
  });
};

export const putUpdateBio = (bio: string) =>
  fetcher(({ uuid }) => `/profile/v1/users/${uuid}/profile`, {
    method: 'put',
    body: {
      profile: {
        bio,
      },
    },
  });

export const postStartVacation = (endDate: Date) =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/vacations`, {
    method: 'post',
    body: {
      vacation: {
        started_at: format(new Date(), 'yyyy-MM-dd'),
        ended_at: format(new Date(endDate), 'yyyy-MM-dd'),
      },
    },
  });

export const endVacation = () =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/active_vacation`, {
    method: 'delete',
  });

export const fetchUserSearchFilters = makeFetcher<BaseUserSearchFilterResponse>(
  () => '/v1/search_filters',
);

export const updateFastShipping = ({
  enabled,
  zip,
}: {
  enabled?: boolean;
  zip?: string;
}) =>
  fetcher('/v1/search_filters/fast_shipping', {
    method: 'put',
    params: {
      search_filter: {
        enabled,
        zip,
      },
    },
  });

export const updateCanadianSeller = ({ enabled }: { enabled?: boolean }) =>
  fetcher('/v1/search_filters/canadian_seller', {
    method: 'put',
    params: {
      search_filter: {
        enabled,
      },
    },
  });

export const reduceEnabledSearchFilters = (
  filters?: BaseUserSearchFilterResponse | null,
  fastShipping?: string | null,
  canadianSellers?: string | null,
) => {
  return {
    ...filters
      ?.filter(s => {
        if (!s.query) {
          return false;
        }

        if (s.slug === 'fast_shipping' && fastShipping === '1') {
          return true;
        }

        if (s.slug === 'canadian_seller' && canadianSellers === '1') {
          return true;
        }

        return false;
      })
      ?.reduce((p, c) => ({ ...p, ...c.query }), {}),
  };
};

export const deleteSelfUnrestrict = () =>
  fetcher(({ uuid }) => `/iam/v1/auth/user/${uuid}/restriction`, {
    method: 'delete',
    body: {
      reason: 'Self-unrestrict',
    },
  });

export const putUpdateAccountEmail = (email: string) =>
  fetcher(({ sub }) => `/iam/v1/auth/user/${sub}`, {
    method: 'put',
    body: {
      email,
    },
  });

export const putUpdateAccountUsername = (username: string) =>
  fetcher(({ sub }) => `/iam/v1/auth/user/${sub}`, {
    method: 'put',
    body: {
      new_username: username,
    },
  });

export const putUpdateAccountPassword = (
  current_password: string,
  new_password: string,
) =>
  fetcher(({ sub }) => `/iam/v1/auth/user/${sub}`, {
    method: 'put',
    body: {
      password: current_password,
      new_password,
    },
  });

export const fetchActiveVacation = makeFetcher<RailsVacation | undefined>(
  () => [
    ({ uuid }) => `/v1/users/${uuid}/active_vacation`,
    {
      ignoreErrors: true,
    },
  ],
);

export const fetchV2Session = makeFetcher<RailsV2Session, []>(
  () => '/v2/session',
);

export const fetchUserConfiguration = makeFetcher<RailsUserConfiguration>(
  () => [({ uuid }) => `/v1/users/${uuid}/configuration`],
);

export const fetchUserBumpInformation = makeFetcher<{
  available_bumps: number;
  items_bumped: number;
}>(() => [({ uuid }) => `/v1/users/${uuid}/bump_attributes`]);

export const postEmailCapture = (
  email: string,
  type: 'TRADE_IN_EVENTS' | 'FOOTER',
  zip?: string,
) =>
  fetcher('/iam/auth/v1/trade_in/event_alert_email', {
    method: 'post',
    body: {
      email,
      zip,
      type,
    },
  });
