import { getEnv } from 'common-nextjs';
import fireGtagEvent from '~/services/analytics/fireGtagEvent';

export default function analyticsInitialize() {
  fireGtagEvent('js', new Date());

  if (getEnv() == 'production') {
    if (getEnv('GA_TRACKING_ID')) {
      fireGtagEvent('config', getEnv('GA_TRACKING_ID'), {
        send_page_view: false,
      });
    }

    if (getEnv('GA4_TRACKING_ID')) {
      fireGtagEvent('config', getEnv('GA4_TRACKING_ID'), {
        send_page_view: false,
      });
    }

    if (getEnv('ADWORDS_KEY')) {
      fireGtagEvent('config', getEnv('ADWORDS_KEY'), {
        send_page_view: false,
      });
    }

    if (window.ttq) {
      window.ttq.load('BS86FP67TJFGI7V4HFRG');
    }
  } else {
    console.log('Analytics initialized, but only in production.');
  }
}
