import React, { useContext } from 'react';
import { AbTestBucket, ActiveAbTests } from '~/api/abTest';

export type TestsObject = Partial<{ [key in ActiveAbTests]: AbTestBucket }>;

interface IAbTestContext extends TestsObject {}

export const AbTestContext = React.createContext<IAbTestContext>({});

// This could be undefined in the case of history hijacking.
export function useAbTestContext(): IAbTestContext | undefined {
  return useContext(AbTestContext);
}

export function useAbTests(): IAbTestContext {
  return useContext(AbTestContext) || {};
}
