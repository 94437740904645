import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initializeFromItem } from '~/store/listFlow/initializationActions';
import {
  changeCategory,
  initializeCategory,
} from '~/store/listFlow/slices/info';
import { RootState } from '~/store/rootReducer';
import { DiscountedShippingDiscount } from '~/typings/services/commerce/shipping';
import { RailsParcel } from '~/typings/services/rails/item';

function calculateGirth(
  lengthRaw: number,
  widthRaw: number,
  heightRaw: number,
) {
  // sort desc, destructure correct order
  const [length, width, height] = [lengthRaw, widthRaw, heightRaw].sort(
    (a, b) => b - a,
  );
  return length + 2 * width + 2 * height;
}

function girthRequiresCustomDimensions(parcel: ListFlowParcel) {
  return (
    calculateGirth(parcel.length || 5, parcel.width || 5, parcel.height || 5) >=
    72
  );
}

// All units are stored in imperial and converted to metric later
export interface ListFlowParcel {
  id?: number;
  length?: number;
  width?: number;
  height?: number;
  weight?: number;
  lockedDimensions: boolean;
}

function railsParcelToListFlowParcel(railsParcel: RailsParcel): ListFlowParcel {
  return {
    id: railsParcel.id,
    length: railsParcel.length,
    width: railsParcel.width,
    height: railsParcel.height,
    weight: railsParcel.weight,
    lockedDimensions: railsParcel.lock_dimensions,
  };
}

export interface ListFlowShippingState {
  categoryParcel?: ListFlowParcel;
  customParcel?: ListFlowParcel;

  discount?: {
    amount: number;
    label: string;
  } | null;

  useCategoryParcel?: boolean;
  requiresCustomDimensions?: boolean;
  useShippingInsurance?: boolean;

  useYourOwnLabel: boolean;
  ownLabelPriceUS?: number;
  ownLabelPriceCA?: number;

  // Displays a warning when discounted shipping was forcibly removed
  // when the user toggles on custom dimensions.
  showShippingDiscountNowInvalidWarning?: boolean;

  shipsFromCountry: 'US' | 'CA';

  // When this is `true`, hide the Use Custom Parcel and only allow the parcel to be equal to the
  // category parcel.
  lockedDimensions?: boolean;
}

export const listFlowShippingInitialState: ListFlowShippingState = {
  discount: null,
  shipsFromCountry: 'US',
  useCategoryParcel: true,
  useYourOwnLabel: false,
  useShippingInsurance: false,
};

const listFlowShippingSlice = createSlice({
  initialState: listFlowShippingInitialState,
  name: 'listFlow/shipping',
  reducers: {
    toggleUseCategoryParcel(
      state,
      action: PayloadAction<{ enabled: boolean }>,
    ) {
      const { enabled } = action.payload;
      if (enabled) {
        state.useCategoryParcel = true;
        state.requiresCustomDimensions = false;
        state.showShippingDiscountNowInvalidWarning = false;
        state.customParcel = state.categoryParcel;
        state.useShippingInsurance = false;
      } else {
        state.useCategoryParcel = false;
        if (state.discount != null && state.discount.amount > 0) {
          state.showShippingDiscountNowInvalidWarning = true;
        }
        if (state.customParcel) {
          state.requiresCustomDimensions = girthRequiresCustomDimensions(
            state.customParcel,
          );
        } else {
          state.requiresCustomDimensions = false;
        }
        state.discount = null;
      }
    },
    toggleRequiresCustomDimensions(
      state,
      action: PayloadAction<{ enabled: boolean }>,
    ) {
      if (action.payload.enabled) {
        state.requiresCustomDimensions = true;
      } else {
        state.requiresCustomDimensions = false;
      }
    },
    changedShipsFromCountry(state, action: PayloadAction<string>) {
      const country = action.payload;
      if (country !== 'US' && country !== 'CA') {
        state.shipsFromCountry = 'US';
      } else {
        state.shipsFromCountry = country;
      }
    },
    changeShippingDiscount(
      state,
      action: PayloadAction<{ discount: DiscountedShippingDiscount | null }>,
    ) {
      const { discount } = action.payload;
      if (discount) {
        state.discount = {
          amount: discount.amount,
          label: discount.label,
        };
      } else {
        state.discount = null;
      }
    },
    editParcel(state, action: PayloadAction<{ parcel: ListFlowParcel }>) {
      const { parcel } = action.payload;
      state.customParcel = parcel;
    },
    uploadedParcel(state, action: PayloadAction<{ railsParcel: RailsParcel }>) {
      const { railsParcel } = action.payload;
      state.customParcel = railsParcelToListFlowParcel(railsParcel);
    },
    usedOwnLabel(state, action: PayloadAction<{ useOwnLabel: boolean }>) {
      state.useYourOwnLabel = action.payload.useOwnLabel;
    },
    ownLabelShippingPrice(
      state,
      action: PayloadAction<{ country: 'US' | 'CA'; amount?: number }>,
    ) {
      const { country, amount } = action.payload;

      if (country === 'US') {
        state.ownLabelPriceUS = amount;
      } else {
        state.ownLabelPriceCA = amount;
      }
    },
    toggleShippingInsurance(
      state,
      action: PayloadAction<{ useShippingInsurance: boolean }>,
    ) {
      state.useShippingInsurance = action.payload.useShippingInsurance;
    },
  },
  extraReducers: builder => {
    builder
      //! HEY, if you're editing this reducer, check if you need to edit `getListFlowSubmittableItem`
      .addCase(initializeFromItem, (state, action) => {
        const item = action.payload;
        let customParcel: ListFlowParcel | undefined;

        if (item.parcel) {
          customParcel = railsParcelToListFlowParcel(item.parcel);
        } else if (state.categoryParcel) {
          customParcel = state.categoryParcel;
        }

        let useCategoryParcel = true;

        if (
          customParcel &&
          state.categoryParcel &&
          customParcel?.id !== state.categoryParcel?.id
        ) {
          useCategoryParcel = false;
        }

        if (!state.categoryParcel?.id) {
          useCategoryParcel = false;
        }

        const newState: ListFlowShippingState = {
          ...state,
          ...listFlowShippingInitialState,
          customParcel,
          useCategoryParcel,
          useShippingInsurance: item.shipping_insured,
        };

        if (
          item.fixed_cost_shipping_price_ca != null ||
          item.fixed_cost_shipping_price_us != null
        ) {
          newState.useYourOwnLabel = true;

          newState.ownLabelPriceUS =
            item.fixed_cost_shipping_price_us ?? undefined;

          newState.ownLabelPriceCA =
            item.fixed_cost_shipping_price_ca ?? undefined;
        } else if (item.shipping_discount_amount != null) {
          newState.useYourOwnLabel = false;
          newState.discount = {
            label: '',
            amount: item.shipping_discount_amount,
          };
        }

        if (customParcel) {
          newState.requiresCustomDimensions =
            girthRequiresCustomDimensions(customParcel);
        }

        if (
          state.categoryParcel?.lockedDimensions ||
          item.parcel?.lock_dimensions
        ) {
          newState.lockedDimensions = true;
          newState.useCategoryParcel = true;
          newState.customParcel = state.categoryParcel;
          state.requiresCustomDimensions = false;
        } else {
          newState.lockedDimensions = false;
        }

        return newState;
      })
      .addCase(changeCategory, state => {
        state.discount = null;
      })
      .addCase(initializeCategory, (state, action) => {
        const category = action.payload;

        state.categoryParcel = category.default_parcel
          ? railsParcelToListFlowParcel(category.default_parcel)
          : undefined;
        state.customParcel = category.default_parcel
          ? railsParcelToListFlowParcel(category.default_parcel)
          : undefined;

        if (category.default_parcel) {
          state.useCategoryParcel = true;
        } else {
          state.useCategoryParcel = false;
          state.requiresCustomDimensions = false;
          state.customParcel = {
            height: 5,
            width: 5,
            length: 5,
            lockedDimensions: false,
          };
        }

        if (state.customParcel) {
          state.requiresCustomDimensions = girthRequiresCustomDimensions(
            state.customParcel,
          );
        }

        if (category.default_parcel?.lock_dimensions) {
          state.lockedDimensions = true;
          state.customParcel = state.categoryParcel;
          state.requiresCustomDimensions = false;
        } else {
          state.lockedDimensions = false;
        }
      });
  },
});

export const {
  toggleUseCategoryParcel,
  toggleRequiresCustomDimensions,
  changedShipsFromCountry,
  uploadedParcel,
  editParcel,
  changeShippingDiscount,
  usedOwnLabel,
  ownLabelShippingPrice,
  toggleShippingInsurance,
} = listFlowShippingSlice.actions;

const listFlowShippingReducer = listFlowShippingSlice.reducer;

export default listFlowShippingReducer;

export const getListFlowShipping = (state: RootState) =>
  state.listFlow.shipping;

export const isListFlowUsingCategoryParcel = (state: RootState) =>
  state.listFlow.shipping.useCategoryParcel;

export const isListFlowUsingCustomDimensions = (state: RootState) =>
  state.listFlow.shipping.requiresCustomDimensions;

export const getListFlowSelectedDiscountedOption = (state: RootState) =>
  state.listFlow.shipping.discount;

export const getListFlowCustomParcel = (state: RootState) =>
  state.listFlow.shipping.customParcel;

export const getListFlowShipsFromCountry = (state: RootState) =>
  state.listFlow.shipping.shipsFromCountry;

export const getUsingOwnShippingLabel = (state: RootState) =>
  state.listFlow.shipping.useYourOwnLabel;

export const getCustomShippingPrice = (state: RootState) => {
  const us = state.listFlow.shipping.ownLabelPriceUS;
  const ca = state.listFlow.shipping.ownLabelPriceCA;

  return {
    us,
    ca,
  };
};

export const isUsingShippingInsurance = (state: RootState) =>
  state.listFlow.shipping.useShippingInsurance;

export const isShippingDimensionsLocked = (state: RootState) =>
  state.listFlow.shipping.lockedDimensions;
