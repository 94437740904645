import { getQueryClient } from 'common-nextjs';
import { sha1 } from 'js-sha1';
import { fetchReferralCode } from '~/api/sellerAccount';
import fireAnalytics from '~/services/analytics/fireAnalytics';
import fireImpactEvent from '~/services/analytics/fireImpactEvent';
import { ListFlowState } from '~/store/listFlow/ui';
import { RailsItem } from '~/typings/services/rails/item';
import { RailsV1SessionResponse } from '~/typings/services/rails/session';

export const fireVisitedListFlow = () => fireAnalytics('Visited List Item');

export const fireListedItem = async (
  item: RailsItem,
  state?: ListFlowState,
) => {
  const queryClient = getQueryClient();
  const { user } =
    queryClient.getQueryData<RailsV1SessionResponse>(['v1-session']) || {};
  const valueGuide =
    queryClient.getQueryData(['list-flow-value-guide'], {
      type: 'active',
      exact: false,
    }) || {};

  const listCount = user?.properties.list_count || 0;

  const analyticsOptions = {
    category: item.categories[0]?.slug,
    item_id: item.id,
    list_price: item.price,
    // role: yield select(amIAdmin) ? 'admin' : 'seller',
    discount_amount: item.shipping_discount_amount,
    discount_label: state?.shipping.discount?.label,
    label_type: state?.shipping.useYourOwnLabel
      ? 'seller_provided'
      : 'sideline',
    ca_shipping_cost:
      item.fixed_cost_shipping_price_ca || state?.shipping.ownLabelPriceCA,
    us_shipping_cost:
      item.fixed_cost_shipping_price_us || state?.shipping.ownLabelPriceUS,
    auction: state?.pricing?.isAuction || item.auction,

    value_guide_visible: !!valueGuide,
    auto_price_drop: !!state?.pricing?.autoPriceDrop?.enabled,
  };

  if (typeof window !== 'undefined' && user) {
    let referralCode;

    const ireParams: any = {
      orderId: item.id,
      customerId: user.external_id,
      customerStatus: listCount > 0 ? 'Returning' : 'New',
      customerEmail: sha1(user.email),
      items: [
        {
          category: item.categories[0]?.full_name,
          sku: item.id,
          quantity: item.quantity,
          subTotal: item.price,
          name: item.name,
        },
      ],
    };

    try {
      referralCode = await fetchReferralCode();
      if (referralCode.referral_code) {
        ireParams.orderPromoCode = referralCode.referral_code;
      }
    } catch (e) {}

    fireImpactEvent('trackConversion', 22093, ireParams);
  }

  fireAnalytics('Listed Item', null, analyticsOptions);
};
