import { universalSetCookie } from 'common-nextjs';
import type { KnownCookies } from 'common-types';
import { addYears, differenceInSeconds, isPast } from 'date-fns';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { parseCookies } from 'nookies';
import { v4 as uuidv4 } from 'uuid';
import { DecodedJwt, decodeJwt } from './decodeJwt';
import { fetchNewAccessToken } from './private/fetchNewAccessToken';

export interface Session {
  accessToken?: string;
  refreshToken?: string;
  decodedAccessToken: DecodedJwt | null;
  clientId: string;

  modified: {
    accessToken?: boolean;
    refreshToken?: boolean;
    clientId?: boolean;
  };

  __ctx?: NextPageContext | GetServerSidePropsContext | null;
}

export function createDefaultSession(
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
): Session {
  const cookies = parseCookies(ctx) as KnownCookies;
  const clientId = cookies.sls_client_id ?? uuidv4();
  return {
    __ctx: ctx,
    accessToken: undefined,
    refreshToken: undefined,
    decodedAccessToken: null,
    clientId,
    modified: {
      accessToken: true,
      refreshToken: true,
      clientId: !cookies.sls_client_id,
    },
  };
}

export function createNewSession(
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
  accessToken: string,
  refreshToken: string,
): Session {
  const defaultSession = createDefaultSession(ctx);
  return commitSession(
    {
      __ctx: ctx,
      accessToken,
      refreshToken,
      decodedAccessToken: decodeJwt(accessToken),
      clientId: defaultSession.clientId,
      modified: {
        accessToken: true,
        refreshToken: true,
      },
    },
    ctx,
  );
}

/**
 * Reads data from the NextJS context and returns a session object.
 * Does not do any token refreshing. Beware, the access token may be expired.
 *
 * @param ctx
 * @param cookies
 */
export function createSession(
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined | void,
  cookies?: KnownCookies,
): Session {
  const knownCookies = cookies ?? (parseCookies(ctx ?? null) as KnownCookies);

  const accessToken = knownCookies.access_token;
  const refreshToken = knownCookies.refresh_token;
  const clientId = knownCookies.sls_client_id ?? uuidv4();

  const decodedAccessToken = decodeJwt(accessToken);

  return {
    __ctx: ctx as
      | NextPageContext
      | GetServerSidePropsContext
      | null
      | undefined,
    accessToken,
    refreshToken,
    clientId,
    decodedAccessToken,
    modified: {
      accessToken: false,
      refreshToken: false,
      clientId: !cookies?.sls_client_id,
    },
  };
}

/**
 * Reads data from the NextJS context and returns a session object.
 * Performs a token refresh if needed.
 *
 * @param ctx
 * @param cookies
 */
export async function getSession(
  ctx: NextPageContext | GetServerSidePropsContext | null | void | undefined,
  cookies?: KnownCookies, // available just so we don't have to parse cookies twice
): Promise<Session> {
  const session = createSession(ctx ?? null, cookies);
  return await refreshIfNeeded(session, ctx ?? null);
}

export function commitSession(
  session: Session,
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
): Session {
  if (session.modified.accessToken) {
    universalSetCookie(ctx, 'access_token', session.accessToken ?? '', {
      maxAge: 60 * 60 * 24 * 30,
      path: '/',
    });
  }

  if (session.modified.refreshToken) {
    universalSetCookie(ctx, 'refresh_token', session.refreshToken ?? '', {
      maxAge: 60 * 60 * 24 * 30,
      path: '/',
    });
  }

  if (session.modified.clientId && session.clientId) {
    universalSetCookie(ctx, 'sls_client_id', session.clientId, {
      expires: addYears(new Date(), 3),
      path: '/',
    });

    ctx?.res?.setHeader?.('x-session-id', session.clientId);
  }

  return session;
}

export function destroySession(
  session: Session | undefined,
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
): Session {
  if (session) {
    session.accessToken = undefined;
    session.refreshToken = undefined;
    session.decodedAccessToken = null;
    session.modified.accessToken = true;
    session.modified.refreshToken = true;

    return commitSession(session, ctx);
  } else {
    return commitSession(createDefaultSession(ctx), ctx);
  }
}

function isSessionExpired(session: Session): boolean {
  if (!session.decodedAccessToken) {
    return true;
  }

  if (isPast(new Date(session.decodedAccessToken.exp * 1000))) {
    return true;
  }

  return false;
}

function shouldRefreshSession(session: Session): boolean {
  if (!session.accessToken && !session.refreshToken) {
    return false;
  }

  if (!session.decodedAccessToken) {
    return true;
  }

  if (
    differenceInSeconds(
      new Date(session.decodedAccessToken.exp * 1000),
      new Date(),
    ) <= 30
  ) {
    return true;
  }

  return false;
}

async function refreshSession(
  ctx: NextPageContext | GetServerSidePropsContext | undefined | null,
  session: Session,
): Promise<Session> {
  // Log out if no refresh token is available and the session is expired
  if (
    !session.refreshToken &&
    session.accessToken &&
    isSessionExpired(session)
  ) {
    return destroySession(session, ctx);
  }

  if (!shouldRefreshSession(session)) {
    return session;
  }

  try {
    const tokenAuthorization = await fetchNewAccessToken(
      session.refreshToken!,
      session.clientId!,
    );

    return createNewSession(
      ctx,
      tokenAuthorization.access_token,
      tokenAuthorization.refresh_token,
    );
  } catch (e) {
    return destroySession(session, ctx);
  }
}

// All this promise magic is to make sure only one refresh is happening at a time.
export async function refreshIfNeeded(
  session: Session,
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
): Promise<Session> {
  if (typeof document === 'undefined') {
    return await refreshSession(ctx, session);
  }

  if (window.__SLS_REFRESHING_SESSION__) {
    const newSession = await window.__SLS_REFRESHING_SESSION__;
    window.__SLS_REFRESHING_SESSION__ = undefined;
    return newSession;
  }

  window.__SLS_REFRESHING_SESSION__ = new Promise<Session>(async resolve => {
    return resolve(await refreshSession(ctx, session));
  });

  const newSession = await window.__SLS_REFRESHING_SESSION__;
  window.__SLS_REFRESHING_SESSION__ = undefined;
  return newSession;
}

export function isLoggedIn(session = createSession()): boolean {
  return !!session?.decodedAccessToken?.uuid;
}

export function getSessionUsername(
  session = createSession(),
): string | undefined {
  return session?.decodedAccessToken?.sub;
}

export function getSessionUuid(session = createSession()): string | undefined {
  return session?.decodedAccessToken?.uuid;
}

export function isSession(test?: any): boolean {
  if (!test) {
    return false;
  }

  return (
    typeof test == 'object' &&
    '__ctx' in test &&
    typeof test?.modified?.accessToken === 'boolean' &&
    typeof test?.modified?.refreshToken === 'boolean'
  );
}

export function getSessionCookies(session?: Session): KnownCookies {
  return parseCookies(session?.__ctx ?? null) as KnownCookies;
}

export function serializeSession(session: Session): Omit<Session, '__ctx'> {
  return {
    accessToken: session.accessToken,
    refreshToken: session.refreshToken,
    clientId: session.clientId,
    decodedAccessToken: session.decodedAccessToken,
    modified: session.modified,
  };
}
