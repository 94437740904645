import { Session } from '../Session';
import { NextPageContext } from 'next';
import { fetcher, FetcherEndpoint, FetcherOptions } from './fetcher';

type Arr = readonly unknown[];

// Progress on fixing the typing of this function:

type InferArguments<T> = T extends (...t: [...infer R]) => any ? R : never;

type RDescriptor =
  | FetcherEndpoint
  | [endpoint: FetcherEndpoint, config?: FetcherOptions];
type OptionsFn = (...args: any[]) => RDescriptor;

export function makeFetcher<
  TResult,
  Arguments extends readonly any[] = readonly any[],
>(options: OptionsFn): (...args: Arguments) => Promise<TResult> {
  return function (this: Session | NextPageContext | void, ...args: Arguments) {
    const result = options(...args);
    return fetcher.apply<any, any, Promise<TResult>>(
      this,
      Array.isArray(result) ? result : [result],
    );
  };
}
