import { fetcher, makeFetcher } from 'fetcher-session';
import { RailsIntegration } from '~/typings/services/rails/settings';

export const forceEbaySync = makeFetcher<unknown, [number | string]>(
  (itemId: string | number) => [
    `/v1/items/${itemId}/ebay/sync`,
    { method: 'post' },
  ],
);

export const postSyncStatus = makeFetcher<unknown, [number | string, boolean]>(
  (itemId: string | number, sync: boolean) => [
    `/v1/items/${itemId}/ebay`,
    {
      body: {
        ebay: {
          sync,
        },
      },
      method: 'post',
    },
  ],
);

export const fetchUserIntegrations = () =>
  fetcher<RailsIntegration[]>(({ uuid }) => `/v1/users/${uuid}/integrations`);

export const linkEbayAccount = (oauthCode: string) =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/integrations`, {
    method: 'post',
    body: {
      code: oauthCode,
      type: 'ebay',
    },
  });

export const linkEbayItem = (itemId: number, ebayId: string) =>
  fetcher(() => `/v1/items/${itemId}/ebay`, {
    method: 'post',
    body: {
      ebay: {
        id: ebayId,
        sync: true,
      },
    },
  });

export const unlinkEbayAccount = () =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/integrations/ebay`, {
    method: 'delete',
  });

export const unlinkEbayItem = (itemId: number) =>
  fetcher(() => `/v1/items/${itemId}/ebay`, {
    method: 'delete',
  });

export const toggleEbayLink = (enabled: boolean) =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/integrations/ebay`, {
    method: 'put',
    body: {
      integration: {
        state: enabled ? 'enabled' : 'disabled',
      },
    },
  });
