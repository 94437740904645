import { combineReducers } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import listFlowReducer, { ListFlowState } from '~/store/listFlow/ui';
import uiSlice, { UiSliceState } from '~/store/ui/uiSlice';

// TODO: Type inferencing wasn't working on root state, so I had to add it here.
export interface RootState {
  listFlow: ListFlowState;
  ui: UiSliceState;
}

const rootReducer = combineReducers({
  listFlow: listFlowReducer,
  ui: uiSlice,
});

function principalReducer(state: any, action: any) {
  if (!action) {
    console.log('NO ACTION');
    return state;
  }

  if (action.type === HYDRATE) {
    return { ...state, ...action.payload };
  }

  return rootReducer(state, action);
}

export default principalReducer;
