import { getEnv } from 'common-nextjs';

export default function fireImpactEvent(...args: any[]) {
  if (getEnv() !== 'production') {
    console.groupCollapsed('Impact Event');
    args.forEach(arg => console.log(arg));
    console.groupEnd();
  }

  if (window.ire) {
    window.ire(...args);
  }
}
