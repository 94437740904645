import { useQuery } from '@tanstack/react-query';
import { fetchV2Session } from '~/api/user';
import { useSession } from '~/contexts/SessionContext';
import { useCustomEvent } from '~/lib/events/customEvents';
import { minutes5inMS } from '../constants/times';

export function useV2Session(shouldFetch = true) {
  const { loggedIn } = useSession();

  const q = useQuery(['v2-session'], fetchV2Session, {
    enabled: shouldFetch && loggedIn,
    staleTime: minutes5inMS,
  });

  useCustomEvent('sls_logout', q.remove);

  return q;
}
