import type { Metric } from 'web-vitals';
import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';
import { getEnv } from 'common-nextjs';
import fireGtagEvent from '~/services/analytics/fireGtagEvent';

function sendToGoogleAnalytics(metric: Metric) {
  if (typeof window === 'undefined') {
    return;
  }

  const { name, delta, id } = metric;
  const finalValue = Math.round(name === 'CLS' ? delta * 1000 : delta);

  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/gtagjs
  fireGtagEvent('event', name, {
    event_category: 'Web Vitals',
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: finalValue,
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
    // Use a non-interaction event to avoid affecting bounce rate.
    non_interaction: true,
  });

  if (getEnv() === 'staging') {
    console.log(
      `Web Vitals - ${name}: ${finalValue}`,
      metric.isFinal ? 'final' : 'not final',
    );
  }
}

export async function prepareWebVitals() {
  // Disable web-vitals in development
  if (getEnv() !== 'development') {
    getCLS(sendToGoogleAnalytics);
    getFID(sendToGoogleAnalytics);
    getLCP(sendToGoogleAnalytics);
    getTTFB(sendToGoogleAnalytics);
    getFCP(sendToGoogleAnalytics);
  }
}
