import * as Sentry from '@sentry/nextjs';
import { getEnv } from 'common-nextjs';

export function getSentry(): typeof Sentry | undefined {
  if (getEnv('SENTRY_ENABLED') != 'true' && getEnv('SENTRY_ENABLED') != '1') {
    return undefined;
  }

  return Sentry;
}
