import qs from 'qs';

/**
 * Returns a query string prepended by a "?"
 */
export function qsStringify<T extends Record<string, any>>(
  query?: T | null,
  options: qs.IStringifyOptions = {},
) {
  if (!query) {
    return '';
  }

  return qs.stringify(query, {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    skipNulls: true,
    ...options,
  });
}
