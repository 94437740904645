'use client';

import React, { useContext } from 'react';
import type { DeviceData } from './types';

export const DeviceContext = React.createContext<DeviceData | undefined>({
  device: undefined,
  os: undefined,
});

export function useDeviceContext() {
  return useContext(DeviceContext);
}
