import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RailsImage } from '~/typings/services/rails/item';
import dataURItoBlob from '~/utils/dataURIToBlob';
import { initializeFromItem } from '~/store/listFlow/initializationActions';
import { RootState } from '~/store/rootReducer';

export const LF_MAX_IMAGES = 12;

export interface ListFlowImage {
  railsId?: number;
  file?: File | Blob;
  url: string;
}

export interface ListFlowPhotoState {
  images: ListFlowImage[];
}

const initialState: ListFlowPhotoState = {
  images: [],
};

const listFlowPhotoState = createSlice({
  initialState,
  name: 'listFlow/photos',
  reducers: {
    swapPhotoPositions(
      state,
      action: PayloadAction<{ oldIndex: number; newIndex: number }>,
    ) {
      const { oldIndex, newIndex } = action.payload;
      const imageToMove = state.images[oldIndex];
      if (!imageToMove) {
        return;
      }

      state.images.splice(oldIndex, 1);
      state.images.splice(newIndex, 0, imageToMove);
    },
    addNewPhoto(
      state,
      action: PayloadAction<{ file: File; objectUrl: string }>,
    ) {
      const { objectUrl, file } = action.payload;
      state.images.push({
        file: file,
        url: objectUrl,
      });
    },
    uploadedPhotoToRails(
      state,
      action: PayloadAction<{ index: number; image: RailsImage }>,
    ) {
      const { index, image } = action.payload;
      state.images[index] = {
        railsId: image.id,
        url: image.large_url,
      };
    },
    cropImage(state, action: PayloadAction<{ index: number; newUrl: string }>) {
      const { index, newUrl } = action.payload;

      if (state.images[index]) {
        state.images[index] = {
          url: newUrl,
          file: dataURItoBlob(newUrl),
        };
      }
    },
    deletePhoto(state, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload;
      if (state.images[index]) {
        state.images.splice(index, 1);
      }
    },
  },
  extraReducers: builder => {
    //! HEY, if you're editing this reducer, check if you need to edit `getListFlowSubmittableItem`
    builder.addCase(initializeFromItem, (state, action) => {
      const item = action.payload;
      return {
        images: item.images?.map(img => ({
          railsId: img.id,
          url: img.large_url,
        })),
      };
    });
  },
});

export const {
  swapPhotoPositions,
  addNewPhoto,
  deletePhoto,
  uploadedPhotoToRails,
  cropImage,
} = listFlowPhotoState.actions;

const listFlowPhotoReducer = listFlowPhotoState.reducer;
export default listFlowPhotoReducer;
export const getListFlowImages = (state: RootState) =>
  state.listFlow.photos.images;
