'use client';

import { getEnv } from 'common-nextjs';

export default function fireRedditEvent(...args: any[]) {
  if (getEnv() !== 'production') {
    console.groupCollapsed('Reddit Event');
    args.forEach(arg => console.log(arg));
    console.groupEnd();
  }

  if (window.rdt) {
    window.rdt(...args);
  }
}
