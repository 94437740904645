import React from 'react';
import SessionContainer from '~/contexts/SessionContext';
import SaleContainer from './SaleContext';
import { ChromelessContext } from '~/contexts/ChromelessContext';
import { ModalsContainer } from '~/contexts/ModalsContext';
import { InitialData } from '~/lib/initialData/initialData';
import { AbTestContext } from './AbTestContext';
import { ServerSideCookiesContext } from './ServerSideCookies';
import { ThemeProvider } from '~/components/Theme';
import { DeviceContext } from 'common-nextjs';
import { SwapPopoutProvider } from '~/contexts/SwapPopoutContext';

interface Props extends Omit<InitialData, 'pageProps'> {}

const GlobalContexts: React.FC<React.PropsWithChildren<Props>> = ({
  abTests,
  children,
  chromeless,
  device,
  initialSessionState,
  sales,
  serverSideCookies,
}) => (
  <ThemeProvider>
    <ServerSideCookiesContext.Provider value={serverSideCookies || {}}>
      <DeviceContext.Provider value={device}>
        <SessionContainer.Provider
          initialState={{ session: initialSessionState }}
        >
          <SaleContainer.Provider initialState={sales}>
            <ChromelessContext.Provider value={chromeless}>
              <ModalsContainer.Provider initialState={{ modals: [] }}>
                <AbTestContext.Provider value={abTests}>
                  <SwapPopoutProvider>{children}</SwapPopoutProvider>
                </AbTestContext.Provider>
              </ModalsContainer.Provider>
            </ChromelessContext.Provider>
          </SaleContainer.Provider>
        </SessionContainer.Provider>
      </DeviceContext.Provider>
    </ServerSideCookiesContext.Provider>
  </ThemeProvider>
);

export default GlobalContexts;
