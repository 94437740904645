'use client';

import Router from 'next/router';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import urlLib from 'url';

export class Redirected extends Error {
  name: string;
  to: string;
  constructor(to: string, additionalMessage = '') {
    super(`Redirected to ${to} ${additionalMessage}`);
    this.name = 'Redirected';
    this.to = to;
  }
}

function modifyWithLocale<T extends string | undefined>(
  path: T,
  locale?: string,
) {
  if (path?.startsWith('/')) {
    return `/${locale}${path}`;
  } else if (path && /sidelineswap/.test(path!)) {
    const parsedUrl = urlLib.parse(path!);
    return `${parsedUrl.protocol}//${parsedUrl.host}/${locale}${parsedUrl.path}`;
  }

  return path;
}

interface RouterOptions {}

interface RedirectOptions extends RouterOptions {
  as?: string;
  replace?: boolean;
  shallow?: boolean;
  statusCode?: number;
}

export async function redirect(
  ctx: NextPageContext | GetServerSidePropsContext,
  path: string,
  { as, statusCode = 302, replace, ...options }: RedirectOptions = {},
) {
  let truePath = path;
  let trueAs = as;

  if (ctx.locale !== ctx.defaultLocale) {
    truePath = modifyWithLocale(path, ctx.locale);
    trueAs = modifyWithLocale(as, ctx.locale);
  }

  if (ctx.res) {
    ctx.res.writeHead(statusCode, {
      Location: trueAs || truePath,
    });
    ctx.res.end();
  } else {
    if (replace) {
      await Router.replace(truePath, trueAs, options);
    } else {
      await Router.push(truePath, trueAs, options);
    }
  }
  return new Redirected(trueAs || truePath);
}
