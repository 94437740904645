import React, { useContext } from 'react';

export interface ChromelessState {
  isChromeless: boolean;
}

export const ChromelessContext = React.createContext<ChromelessState>({
  isChromeless: false,
});

export default function useChromelessContext() {
  return (
    useContext(ChromelessContext) || {
      isChromeless: false,
    }
  );
}
