'use client';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { KnownCookie } from 'common-types';
import {
  ApiError,
  DecodedJwt,
  Session,
  createDefaultSession,
  createNewSession,
  destroySession,
  getSession,
  getSessionUsername,
  getSessionUuid,
  isLoggedIn,
} from 'fetcher-session';
import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';
import { createContainer, useContainer } from 'unstated-next';
import { fetchIamUser } from '~/api/auth';
import { removeKnownCookie } from '~/hooks/cookies';
import { useCustomEvent } from '~/lib/events/customEvents';
import { fireSignedOutEvent } from '~/services/analytics/events/auth';
import {
  trackingIdentify,
  trackingIdentifyAnonymous,
  trackingUnidentify,
} from '~/services/analytics/identify';
import { IAMUser } from '~/typings/services/iam/auth';

function useSessionContainer(
  {
    session: initialState,
    iamUser: initialIamUser,
  }: { session: Session; iamUser?: IAMUser } = {
    session: createDefaultSession(null),
  },
): {
  logout: () => void;
  refetchUser: () => void;
  clientId: string;
  decodedToken?: DecodedJwt | null;
  loggedIn: boolean;
  login: (accessToken: string, refreshToken: string) => Promise<void>;
  user?: IAMUser;
} {
  const queryClient = useQueryClient();
  const firedSignedOut = useRef(false);

  const [session, setSession] = useState(initialState);

  const loggedIn = isLoggedIn(session);

  function logout() {
    setSession(destroySession(session, null));

    removeKnownCookie(null, 'sls-seller-account-page-1-form');
    removeKnownCookie(null, 'sls_swp_po_enabled');
    removeKnownCookie(null, 'sls_swp_po');
    localStorage?.removeItem('sls-seller-account-page-1-form' as KnownCookie);

    if (!firedSignedOut.current) {
      firedSignedOut.current = true;
      fireSignedOutEvent();
      trackingUnidentify();
    }

    window.location.reload();

    queryClient.removeQueries({
      exact: false,
    });
    queryClient.clear();
    refetch();
  }

  async function login(accessToken: string, refreshToken: string) {
    firedSignedOut.current = false;

    setSession(createNewSession(null, accessToken, refreshToken));

    Cookies.set('signed_in_before' as KnownCookie, '1', {
      expires: 2000,
    });

    queryClient.removeQueries({
      exact: false,
    });
    queryClient.clear();
    refetch();
  }

  const {
    data: user,
    refetch,
    error: userFetchError,
  } = useQuery<IAMUser | null, ApiError, IAMUser>(
    ['iam-user', getSessionUuid(session) || 'anonymous'],
    () => {
      if (isLoggedIn(session)) {
        return fetchIamUser();
      }

      return null;
    },
    {
      // @ts-ignore
      placeholderData: () => ({
        ...initialState.decodedAccessToken,
        username: getSessionUsername(session),
      }),
      retry: 2,
      initialData: initialIamUser,
      staleTime: 30000,
    },
  );

  useEffect(() => {
    if (user?.roles) {
      trackingIdentify({
        email: user.email,
        username: user.username,
        external_id: user.external_id,
        phoneNumber: user.phone_number
          ? `+${user.phone_country_code}${user.phone_number}`
          : undefined,
      });
    }
  }, [user?.roles]);

  useEffect(() => {
    if (userFetchError) {
      logout();
    }
  }, [userFetchError]);

  useMount(() => {
    if (!loggedIn) {
      trackingIdentifyAnonymous();
    }
  });

  useCustomEvent('sls_logout', logout);
  useCustomEvent('sls_login', async () => {
    const newSession = await getSession();
    setSession(newSession);
    await queryClient.invalidateQueries();
  });

  return {
    loggedIn,
    login,
    logout,
    user,
    clientId: session.clientId,
    decodedToken: session.decodedAccessToken,
    refetchUser: refetch,
  };
}

const SessionContainer = createContainer(useSessionContainer);
export function useSession() {
  return useContainer(SessionContainer);
}
export default SessionContainer;
