'use client';

import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

interface Props {}

export const ScrollRestoration: React.FC<Props> = () => {
  const router = useRouter();
  const cachedScrollPositions = useRef<number[][]>([]);

  useEffect(() => {
    if (!('scrollRestoration' in window.history)) {
      return;
    }

    window.history.scrollRestoration = 'manual';
    let shouldScrollRestore: false | { x: number; y: number } = false;

    router.events.on('routeChangeStart', () => {
      cachedScrollPositions.current.push([window.scrollX, window.scrollY]);
    });

    router.events.on('routeChangeComplete', (url, arg) => {
      if (shouldScrollRestore) {
        const { x, y } = shouldScrollRestore;
        window.scrollTo(x, y);
        shouldScrollRestore = false;
      }
    });

    router.beforePopState(() => {
      const popped = cachedScrollPositions.current.pop();
      if (!popped) {
        return true;
      }

      const [x, y] = popped;
      shouldScrollRestore = { x, y };

      return true;
    });
  }, []);

  return null;
};
