import getConfig from 'next/config';

declare global {
  interface Window {
    __ENV?: Record<string, string>;
  }
}

/**
 * Get public runtime environment variables. If no key is provided, the
 * ENVIRONMENT is returned.
 * @param env
 */
export function getEnv<TEnv extends string | undefined = undefined>(
  env?: TEnv,
): TEnv extends undefined
  ? 'staging' | 'production' | 'development'
  : string | undefined {
  if (env) {
    return (
      getConfig()?.publicRuntimeConfig?.[env] ||
      getConfig()?.serverRuntimeConfig?.[env] ||
      (typeof window !== 'undefined' ? window.__ENV?.[env] : undefined) ||
      (typeof process !== 'undefined' ? process.env[env] : undefined)
    );
  }
  return (
    getConfig()?.publicRuntimeConfig?.ENVIRONMENT ||
    getConfig()?.serverRuntimeConfig?.ENVIRONMENT ||
    (typeof window !== 'undefined' ? window.__ENV?.ENVIRONMENT : undefined) ||
    (typeof process !== 'undefined' ? process.env.ENVIRONMENT : undefined) ||
    'development'
  );
}
