import { KnownCookie } from 'common-types';
import { CookieSerializeOptions } from 'cookie';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { destroyCookie, setCookie } from 'nookies';

export function universalSetCookie(
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
  name: KnownCookie | string,
  value: any,
  options?: CookieSerializeOptions,
): void {
  const cookieOptions = {
    path: '/',
    maxAge: 31557600,
    ...options,
  };

  if (value == null) {
    destroyCookie(ctx, name, cookieOptions);
    if ((ctx?.req as any)?.['cookies']) {
      (ctx?.req as any)['cookies'][name] = undefined;
    }
  } else {
    setCookie(ctx, name, value, cookieOptions);
    if ((ctx?.req as any)?.['cookies']) {
      (ctx?.req as any)['cookies'][name] = value;
    }
  }
}
