'use client';

import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import React, { useState } from 'react';

interface Props {
  pageProps?: any;
  dehydratedSharedState?: DehydratedState;
  dehydratedState?: DehydratedState;
}

export const ReactQueryAppContext: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  dehydratedSharedState,
  dehydratedState,
  pageProps,
}) => {
  const [queryClient] = useState(() => {
    const newQueryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          staleTime: 300000,
        },
      },
    });

    if (typeof window !== 'undefined') {
      window['__sls_reactQueryClient'] = newQueryClient;
    }

    return newQueryClient;
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate
        state={{
          queries: [
            ...(dehydratedState?.queries || []),
            ...(dehydratedSharedState?.queries || []),
            ...(pageProps?.dehydratedState?.queries || []),
          ],
          mutations: [
            ...(dehydratedState?.mutations || []),
            ...(dehydratedSharedState?.mutations || []),
            ...(pageProps?.dehydratedState?.mutations || []),
          ],
        }}
      >
        {children}
      </Hydrate>
    </QueryClientProvider>
  );
};
