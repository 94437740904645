import { getEnv } from 'common-nextjs';

export default function fireFacebookEvent(...args: any[]) {
  if (getEnv() !== 'production') {
    console.groupCollapsed('Facebook Event (fbq)');
    args.forEach(arg => console.log(arg));
    console.groupEnd();
  }

  if (!getEnv('FB_KEY')) {
    console.warn('FB_KEY is not set, did not fire Facebook Event');
    return;
  }

  if (window.fbq) {
    window.fbq(...args);
  }
}
