'use client';

import { useMedia } from 'react-use';
import { useDeviceContext } from './context';

export function useMobile() {
  const deviceContext = useDeviceContext();
  // default to mobile
  return useMedia('(max-width: 767px)', deviceContext?.device === 'mobile');
}
