import { QueryClient } from '@tanstack/react-query';

export function getQueryClient(): QueryClient {
  if (typeof window !== 'undefined') {
    if (!window['__sls_reactQueryClient']) {
      window['__sls_reactQueryClient'] = new QueryClient();
    }

    return window['__sls_reactQueryClient'];
  }

  return new QueryClient();
}
