import { fetcher, makeFetcher } from 'fetcher-session';
import getUTMsFromCookies from '~/services/analytics/getUTMsFromCookies';
import { IAMSession, IAMUser } from '~/typings/services/iam/auth';

export const recaptchaHeader = 'g-recaptcha-token';

export const fetchIamUser = makeFetcher<IAMUser>(() => [
  ({ uuid }) => `/iam/v1/auth/user/${uuid}`,
]);

export const updateAccountSettings = (values: any) =>
  fetcher<IAMUser>(({ sub }) => `/iam/v1/auth/user/${sub}`, {
    method: 'put',
    body: { ...values },
  });

export const postAttributions = async () => {
  const attribution = getUTMsFromCookies();
  if (!attribution) {
    return;
  }

  return await fetcher(({ uuid }) => `/v1/users/${uuid}/attributions`, {
    method: 'post',
    body: {
      attribution,
    },
  });
};

export const fetchIamUserSessions = makeFetcher<IAMSession[]>(
  (platform: 'ios' | 'android') => [
    ({ uuid }) => `/iam/v1/auth/user/${uuid}/sessions`,
    {
      params: {
        platform,
        limit: 1,
      },
    },
  ],
);
