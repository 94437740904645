import { ApiError, FieldError } from './ApiError';

function processElement(err: FieldError[]) {
  const found = err.find(e => !!e.message || !!e.detail);
  const foundMessage = found ? found.message || found.detail : undefined;
  if (foundMessage) {
    return foundMessage;
  } else {
    const foundCode = err.find(e => !!e.code)?.code;
    return `Code: ${foundCode}`;
  }
}

export function getErrorMessage(error?: unknown | string | ApiError | Error) {
  // Remove unknown just because it's easier...
  const err = error as string | ApiError | Error;

  if (err == null) {
    return 'Something went wrong...';
  }

  if (typeof err == 'string') {
    return err;
  }

  let message: string;
  if ('errors' in err && Array.isArray(err.errors) && err.errors.length > 0) {
    message = processElement(err.errors);
  } else if (Array.isArray(err)) {
    message = processElement(err);
  } else {
    // @ts-ignore
    message = err.message || err['detail'];
  }

  return message;
}
