import { fetcher, makeFetcher } from 'fetcher-session';
import {
  RailsItem,
  RailsItemDetail,
  RailsShipping,
} from '~/typings/services/rails/item';
import { RailsUser } from '~/typings/services/rails/user';
import { OfferByItemId } from './swaps';

type ItemId = number | string;

export const fetchItemById = makeFetcher<
  RailsItem,
  [itemId: ItemId, track?: boolean]
>((itemId, track = false) => [
  `/v1/items/${itemId}`,
  {
    params: {
      track,
    },
  },
]);

export const fetchFavoriters = makeFetcher<
  Pick<RailsUser, 'id' | 'username' | 'avatar_url' | 'following'>[],
  [itemId: ItemId]
>(itemId => `/v1/items/${itemId}/favoriters`);

export const favoriteItem = makeFetcher<
  Pick<RailsItem, 'id' | 'favorite' | 'favoriters_count'>[],
  [itemId: ItemId]
>(itemId => [`/v1/items/${itemId}/favorite`, { method: 'post' }]);

export const unfavoriteItem = makeFetcher<
  Pick<RailsItem, 'id' | 'favorite' | 'favoriters_count'>[],
  [itemId: ItemId]
>(itemId => [`/v1/items/${itemId}/favorite`, { method: 'delete' }]);

export const postReportItem = makeFetcher<
  never,
  [itemId: ItemId, reason: string, message?: string]
>((itemId, reason: string, message?: string) => [
  `/v1/items/${itemId}/report`,
  {
    body: {
      reason,
      message,
    },
    method: 'post',
  },
]);

export const postRemoveItem = makeFetcher<
  RailsItem,
  [itemId: ItemId, reason: string, message?: string]
>((itemId, reason: string, message?: string) => [
  `/v1/items/${itemId}/remove`,
  {
    body: {
      reason_removed: reason,
      message,
    },
    method: 'post',
  },
]);

export const deleteDiscardDraft = makeFetcher<never, [itemId: ItemId]>(
  itemId => [
    `/v1/items/${itemId}/discard_draft`,
    {
      method: 'delete',
    },
  ],
);

export const postTakeDownItem = makeFetcher<never, [itemId: ItemId]>(itemId => [
  `/v1/items/${itemId}/take_down`,
  {
    method: 'post',
  },
]);

export const postPublishItem = makeFetcher<RailsItem, [itemId: ItemId]>(
  itemId => [
    `/v1/items/${itemId}/publish`,
    {
      method: 'post',
    },
  ],
);

export const addItemToSale = makeFetcher<
  never,
  [itemId: ItemId, saleId: number | string]
>((itemId, saleId) => [
  `/v1/items/${itemId}/sales/${saleId}`,
  {
    method: 'put',
  },
]);

export const removeItemFromSale = makeFetcher<
  never,
  [itemId: ItemId, saleId: number | string]
>((itemId, saleId) => [
  `/v1/items/${itemId}/sales/${saleId}`,
  {
    method: 'delete',
  },
]);

export const unreportItem = makeFetcher<never, [itemId: ItemId]>(itemId => [
  `/v1/items/${itemId}/unreport`,
  {
    method: 'post',
  },
]);

export const itemRemoveGmc = makeFetcher<never, [itemId: ItemId]>(itemId => [
  `/v1/items/${itemId}/remove_gmc`,
  {
    method: 'post',
  },
]);

export const fetchItemShipping = makeFetcher<
  RailsShipping,
  [
    itemId: ItemId,
    params: { zip: string; country: string } | { ship_to_address_id: number },
  ]
>(
  (
    itemId,
    params: { zip: string; country: string } | { ship_to_address_id: number },
  ) => [
    `/commerce/ship/v1/items/${itemId}/rate`,
    {
      params,
    },
  ],
);

export const postBumpItem = (itemId: ItemId) =>
  fetcher<RailsItem>(`/v1/items/${itemId}/bump`, {
    method: 'post',
  });

export const postV2BumpItem = (itemId: ItemId) =>
  fetcher<RailsItem>(`/v2/items/${itemId}/bump`, {
    method: 'post',
  });

export const priceDropItem = makeFetcher<
  RailsItem,
  [itemId: ItemId, newPrice: number | string]
>((itemId, newPrice) => [
  `/v1/items/${itemId}/price_drop`,
  {
    method: 'put',
    body: {
      price: newPrice,
    },
  },
]);

export interface RailsItemSwapInsights {
  swap: null | {
    id: number;
    offer: null | {
      id: number;
      amount: number;
    };
  };
  offer_history: OfferByItemId[];
  item: Pick<
    RailsItem,
    | 'id'
    | 'name'
    | 'price'
    | 'cost'
    | 'location_id'
    | 'views_count'
    | 'favoriters_count'
    | 'published_at'
    | 'shipping_discount_amount'
    | 'primary_image'
    | 'event_name'
  > & {
    details: {
      name: string;
      value_guides_required: boolean;
      values: {
        id: number;
        name: string;
        slug: string;
      }[];
    }[];
  };
  recent_sales: {
    details: RailsItemDetail[];
    images: string[];
    item_id: number;
    list_price: number;
    name: string;
    platform: string;
    published_at: string; // Date
    sold_at: string; // Date
    sold_price: number;
    time_to_sell: number;
  }[];
  value_guide: {
    avg_resale_price: {
      low: number;
      median: number;
      high: number;
    };
    recent_listings: number;
    recent_sales: number;
    sell_through_rate: {
      rating: null | 'high' | 'medium' | 'low';
      percentage: number;
    };
    suggested_price: number;
    total_available: number;
  };
}

export async function fetchItemOrSwapInsights(
  itemId?: ItemId,
  swapId?: number,
  detailFilters?: string[],
) {
  return await fetcher<RailsItemSwapInsights>(`/v1/items/${itemId}/insight`, {
    params: {
      swap_id: swapId,
      detail: detailFilters,
    },
  });
}
