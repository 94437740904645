import { useCookie } from 'react-use';
import { KnownCookie, KnownCookies } from 'common-types';
import Cookies from 'js-cookie';
import { useServerSideCookies } from '~/contexts/ServerSideCookies';

type UseKnownCookieReturn<T extends KnownCookie> = [
  value: KnownCookies[T] | null,
  setCookie: (
    newValue: KnownCookies[T],
    options?: Cookies.CookieAttributes | undefined,
  ) => void,
  destroyCookie: () => void,
];

export default function useKnownCookie<T extends KnownCookie>(
  cookieName: T,
): UseKnownCookieReturn<T> {
  const initialCookies = useServerSideCookies();
  const [cookieValue, setCookieValue, destroyCookie] = useCookie(
    cookieName,
  ) as UseKnownCookieReturn<T>;

  // During SSR, return simply the cookie.
  if (typeof window == 'undefined') {
    // No-ops
    return [initialCookies[cookieName], setCookieValue, destroyCookie];
  }

  return [
    cookieValue,
    (newValue, options) => {
      if (newValue) {
        setCookieValue(newValue, {
          path: '/',
          expires: 365,
          ...options,
        });
      } else {
        destroyCookie();
      }
    },
    destroyCookie,
  ];
}
