import { getEnv } from 'common-nextjs';

export default function fireGAEvent(...args: any[]) {
  if (getEnv() !== 'production') {
    console.groupCollapsed('GA Event');
    args.forEach(arg => console.log(arg));
    console.groupEnd();
  }

  if (window.ga) {
    window.ga(...args);
  }
}
