import { AppContext } from 'next/app';
import { generateLoginLink } from '~/components/Links/LoginLink';
import { qsParse, qsStringify } from 'utils';
import { NextPageContext } from 'next';
import {
  createSession,
  getSessionUuid,
  isLoggedIn,
  Session,
} from 'fetcher-session';
import { redirect, Redirected } from 'common-nextjs';

class PageGetInitialPropsFailed extends Error {
  private url: string | undefined;
  private userClientId: string | undefined;
  private decodedUserToken: string | undefined;

  constructor(error: Error, ctx: NextPageContext) {
    const session = createSession(ctx);
    super(
      `${error.name}: ${error.message}; pageUrl="${ctx.asPath}" userClientId="${session.clientId}"`,
    );
    this.name = 'PageGetInitialPropsFailed';

    this.url = ctx.asPath;
    this.userClientId = session.clientId;
    if (session?.decodedAccessToken) {
      this.decodedUserToken = JSON.stringify(session?.decodedAccessToken);
    }
  }
}

export async function performRedirect(appCtx: AppContext, session: Session) {
  const { ctx, router, Component } = appCtx;
  let shouldRedirect = false;

  const loggedIn = isLoggedIn(session);

  if (ctx.req) {
    if (Component['securePage'] && !loggedIn) {
      shouldRedirect = true;
    }
  } else {
    if (Component['securePage'] && !getSessionUuid(session)) {
      shouldRedirect = true;
    }
  }

  if (shouldRedirect) {
    const params = {
      destination: router.asPath,
      message: Component['securePageMessage'],
    };
    throw await redirect(ctx, generateLoginLink(params));
  }
}

export async function getPageProps(appCtx: AppContext) {
  const { ctx, Component } = appCtx;
  if (Component.getInitialProps) {
    try {
      // Stringify and re-parse to avoid keys that end in []
      const query = qsParse(qsStringify(ctx.query));

      return await Component.getInitialProps({
        ...ctx,
        query,
      });
    } catch (e) {
      const error = e as Error;
      if (error instanceof Redirected) {
        // Don't throw page errors that are actually intentional
        return {};
      } else {
        throw error;
      }
    }
  } else {
    return {};
  }
}
