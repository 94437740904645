import { useMobile } from 'common-nextjs';
import React, { PropsWithChildren } from 'react';
import { useSession } from '~/contexts/SessionContext';
import useKnownCookie from '~/hooks/useKnownCookie';

interface SwapPopoutContextValue {
  enabled: boolean;
  swapIds: SwapPopoutValue[];

  openSwap(swapId: number): void;
  closeSwap(swapId: number): void;
  removeSwap(swapId: number): void;

  setEnabled(enabled: boolean): void;
}

const MAX_SWAPS = 6;

// o indicates open
const swapRe = /(o)?(\d+)/;

type SwapPopoutValue = {
  swapId: number;
  open: boolean;
};

/**
 * Deserialize the swapIds cookie into an array of swapIds
 * @param swapIds
 */
function deserializeSwaps(swapIds?: string | null): SwapPopoutValue[] {
  if (!swapIds) {
    return [];
  }

  const ids = swapIds.split('|');

  const out: SwapPopoutValue[] = [];

  for (const id of ids) {
    const match = swapRe.exec(id);

    if (!match) {
      continue;
    }

    const swapId = parseInt(match[2]);

    if (!swapId) {
      continue;
    }

    out.push({
      swapId: swapId,
      open: !!match[1],
    });
  }

  return out;
}

function serializeSwaps(swapIds: SwapPopoutValue[]): string {
  return swapIds
    .map(({ swapId, open }) => `${open ? 'o' : ''}${swapId}`)
    .join('|');
}

const initialState = {
  enabled: false,
  swapIds: [],
  openSwap: () => {},
  closeSwap: () => {},
  removeSwap: () => {},
  setEnabled: () => {},
};
export const SwapPopoutContext =
  React.createContext<SwapPopoutContextValue>(initialState);

export function useSwapPopout() {
  const context = React.useContext(SwapPopoutContext);

  if (!context) {
    // throw new Error('useSwapPopout must be used within a SwapPopoutProvider');
    console.error('useSwapPopout must be used within a SwapPopoutProvider');
  }

  return context;
}

export const SwapPopoutProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { loggedIn } = useSession();
  const mobile = useMobile();
  const [cookieEnabled, setCookieEnabled] =
    useKnownCookie('sls_swp_po_enabled');
  const [swapIds, setSwapIds] = useKnownCookie('sls_swp_po');

  const enabled = !mobile && !!cookieEnabled && loggedIn;
  const deserializedSwaps = deserializeSwaps(swapIds);

  function handleOpenSwap(swapId: number) {
    // First, find out if this swapId is in the list.
    // If so, just open it and close the rest
    // If not, add it and open it and close the rest

    const swap = deserializedSwaps.find(({ swapId: id }) => id === swapId);

    if (swap) {
      // swap.open = true;
      setSwapIds(
        serializeSwaps(
          deserializedSwaps.map(({ swapId, open }) => ({
            swapId,
            open: swapId === swap.swapId,
          })),
        ),
      );
    } else {
      setSwapIds(
        serializeSwaps(
          deserializedSwaps
            .map(({ swapId }) => ({ swapId, open: false }))
            .concat({ swapId, open: true })
            .slice(-MAX_SWAPS), // only keep the last MAX_SWAPS swaps. The first one opened will be the first one closed
        ),
      );
    }
  }

  function handleCloseSwap(closingSwapId: number) {
    setSwapIds(
      serializeSwaps(
        deserializedSwaps.map(({ swapId }) => ({
          swapId,
          open: false, // since only one swap can be open at a time, just close all of them
        })),
      ),
    );
  }

  function handleRemoveSwap(swapId: number) {
    setSwapIds(
      serializeSwaps(
        deserializedSwaps.filter(({ swapId: id }) => id !== swapId),
      ),
    );
  }

  function handleSetEnabled(newEnabled: boolean) {
    setCookieEnabled(newEnabled ? '1' : undefined);

    if (!newEnabled) {
      setSwapIds(undefined);
    }
  }

  return (
    <SwapPopoutContext.Provider
      value={{
        enabled,
        swapIds: deserializedSwaps,
        openSwap: handleOpenSwap,
        closeSwap: handleCloseSwap,
        removeSwap: handleRemoveSwap,
        setEnabled: handleSetEnabled,
      }}
    >
      {children}
    </SwapPopoutContext.Provider>
  );
};
