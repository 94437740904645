import { AppContext } from 'next/app';
import { getPageProps, performRedirect } from '~/lib/initialData/common';
import { ClientLRU, InitialData } from '~/lib/initialData/initialData';
import LRUCache from 'lru-cache';
import { SaleInitialState } from '~/contexts/SaleContext';
import { KnownCookies } from 'common-types';
import { parseCookies } from 'nookies';
import { getSession } from 'fetcher-session';
import { getParsedUserAgent, Redirected } from 'common-nextjs';

const clientLru = new LRUCache<string, any>();

function getFromCache<K extends keyof ClientLRU>(
  key: K,
): ClientLRU[K] | undefined {
  return clientLru.get(key);
}

function setIntoCache<K extends keyof ClientLRU>(
  key: K,
  value?: ClientLRU[K],
): void {
  clientLru.set(key, value);
}

function getCachedInitialData(
  appCtx: AppContext,
): Omit<InitialData, 'pageProps'> {
  const cookies: KnownCookies = parseCookies(appCtx.ctx);
  const sales: SaleInitialState = {
    dismissed: !!cookies.dismissed_sale,
  };

  return {
    sales,
    abTests: getFromCache('abTests')!,
    chromeless: getFromCache('chromeless')!,
    initialSessionState: getFromCache('initialSessionState')!,
  };
}

export function setCacheOnClient(props: InitialData) {
  setIntoCache('initialSessionState', props.initialSessionState);
  setIntoCache('chromeless', props.chromeless);
  setIntoCache('abTests', props.abTests);
}

export default async function getInitialClientProps(
  appCtx: AppContext,
): Promise<InitialData> {
  const initialData = await getCachedInitialData(appCtx);

  const session = await getSession(appCtx.ctx);

  await performRedirect(appCtx, session);
  let pageProps: {} = {};
  try {
    pageProps = await getPageProps(appCtx);
  } catch (e) {
    if (e instanceof Redirected) {
      return {
        ...initialData,
        pageProps,
        initialSessionState: session,
        device: getParsedUserAgent(appCtx),
      };
    }
    throw e;
  }

  return {
    ...initialData,
    pageProps,
    device: getParsedUserAgent(appCtx),
    initialSessionState: session,
  };
}
