import { getEnv } from './getEnv';

export function selectEnv<P, S = P, D = S>(
  production: P,
  staging?: S | D | P,
  development?: D | S | P,
) {
  const env = getEnv();

  if (env === 'production') {
    return production;
  } else if (env === 'staging') {
    return staging ?? development ?? production;
  } else {
    return development ?? staging ?? production;
  }
}
