import Head from 'next/head';
import React from 'react';
import ICON_180 from '~/public/static/icons/icon-180x180.png';
import ICON_32 from '~/public/static/icons/icon-32x32.png';
import ICON_57 from '~/public/static/icons/icon-57x57.png';
import ICON_60 from '~/public/static/icons/icon-60x60.png';
import ICON_72 from '~/public/static/icons/icon-72x72.png';

interface Props {}

const MetaAppIcons: React.FC<Props> = () => {
  return (
    <Head>
      <link rel="shortcut icon" type="image/png" href={ICON_32.src} />
      <link rel="icon" type="image/png" href={ICON_72.src} sizes="72x72" />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={ICON_57.src}
        sizes="57x57"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={ICON_60.src}
        sizes="60x60"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={ICON_72.src}
        sizes="72x72"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={ICON_180.src}
        sizes="180x180"
      />
    </Head>
  );
};

export default MetaAppIcons;
