import jwtDecode from 'jwt-decode';

export interface DecodedJwt {
  sub: string; // Username
  aud: string;
  nbf: number;
  iss: string;
  external_id: string;
  scopes: string[];
  exp: number;
  iat: number;
  uuid: string;
  jti: string;
  org_id?: string;
}

export function decodeJwt(accessToken?: string) {
  if (accessToken) {
    try {
      return jwtDecode<DecodedJwt>(accessToken);
    } catch (e) {
      return null;
    }
  }
  return null;
}
