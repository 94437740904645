import { extractPathname } from '../fetcher/extractPathname';

export interface FieldError {
  field?: string;
  detail?: string;
  message?: string;
  codes?: any[];
  code?: number | string;
}

export interface IApiError {
  message: string;
  endpoint?: string;
  params?: Record<string, any>;
  status?: number;
  errors?: FieldError[];
  meta?: Record<string, any>;
}

export class ApiError extends Error implements IApiError {
  endpoint?: string | undefined;
  params?: Record<string, any> | undefined;
  status?: number | undefined;
  errors?: FieldError[] | undefined;
  meta?: Record<string, any> | undefined;

  constructor(message?: string) {
    super(message);
    this.name = 'ApiError';
  }

  static fromResponse(response: Response, bodyText?: string) {
    let endpoint: string;

    try {
      endpoint = extractPathname(response.url);
    } catch (e) {
      endpoint = response.url;
    }

    const err = new ApiError(
      `status="${response.status}" endpoint="${endpoint}" responseBody="${bodyText}"`,
    );
    err.endpoint = endpoint;
    if (bodyText) {
      try {
        const parsedBody = JSON.parse(bodyText);
        err.errors = parsedBody.errors;
        err.meta = parsedBody.meta;
      } catch (e) {}
    }
    err.status = response.status;
    return err;
  }

  static fromApiError(other: ApiError) {
    const err = new ApiError(other.message);
    err.errors = other.errors;
    err.endpoint = other.endpoint;
    err.status = other.status;

    return err;
  }
}

export class AuthRefreshFailure extends ApiError {
  constructor(message?: string) {
    super(message);
    this.name = 'AuthRefreshError';
  }
}
