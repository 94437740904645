import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { clsx } from 'clsx';
import React from 'react';

interface BaseAction {
  disabled?: boolean;
  label: React.ReactNode;
}

interface ButtonAction extends BaseAction {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface NewTabAction extends BaseAction {
  newTabHref: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

interface HrefAction extends BaseAction {
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export type MessageProps = React.HTMLAttributes<HTMLDivElement> & {
  action?: ButtonAction | NewTabAction | HrefAction;
  header?: React.ReactNode;
  icon?: React.ReactNode | 'auto';
  type?: 'error' | 'info' | 'success' | 'warning';
  error?: boolean;
  info?: boolean;
  success?: boolean;
  warning?: boolean;
  variant?: 'contained' | 'outlined';
};

export const Message = React.forwardRef<any, MessageProps>(
  (
    {
      action,
      children,
      className,
      error,
      header,
      icon,
      info,
      success,
      type,
      warning,
      variant = 'contained',
      ...props
    },
    ref,
  ) => {
    const containerCx = clsx(
      'prose prose-p:my-2 relative my-4 w-full px-4 text-left first:mt-0 last:mb-0',
      {
        'flex flex-col md:flex-row': icon || action,
      },
      variant === 'contained' && [
        'rounded py-2',
        {
          'bg-red-200': type === 'error' || error,
          'bg-blue-200': type === 'info' || info,
          'bg-yellow-200': type === 'warning' || warning,
          'bg-green-200': type === 'success' || success,
          'bg-pastel-green-300':
            !type && !error && !info && !warning && !success,
        },
      ],
      variant === 'outlined' && [
        'shadow-raised border-l-4 bg-white py-4',
        {
          'border-red-500': type === 'error' || error,
          'border-blue-500': type === 'info' || info,
          'border-yellow-500': type === 'warning' || warning,
          'border-green-500': type === 'success' || success,
          'border-mint-green-400':
            !type && !error && !info && !warning && !success,
        },
      ],
      className,
    );

    let renderedAction: React.ReactNode = null;

    if (action) {
      const actionCx = clsx(
        'mb-2 mt-4 inline-block w-full rounded px-4 py-1.5 text-center text-sm text-white no-underline transition duration-200 hover:text-white focus:ring focus:ring-opacity-20 md:mb-0 md:ml-3 md:mt-1 md:w-auto',
        {
          'bg-red-500 ring-red-500 hover:bg-red-600 active:bg-red-700':
            type === 'error' || error,
          'bg-blue-500 ring-blue-500 hover:bg-blue-600 active:bg-blue-700':
            type === 'info' || info,
          'bg-yellow-500 ring-yellow-500 hover:bg-yellow-600 active:bg-yellow-700':
            type === 'warning' || warning,
          'bg-green-500 ring-green-500 hover:bg-green-600 active:bg-green-700':
            type === 'success' || success,
          'bg-turf-green-500 ring-turf-green-500':
            !type && !error && !info && !warning && !success,
        },
      );

      if ('newTabHref' in action) {
        renderedAction = (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={actionCx}
            href={action.newTabHref}
            onClick={action.onClick}
          >
            {action.label}
          </a>
        );
      } else if ('href' in action) {
        renderedAction = (
          <a className={actionCx} href={action.href} onClick={action.onClick}>
            {action.label}
          </a>
        );
      } else {
        renderedAction = (
          <button
            className={actionCx}
            disabled={action.disabled}
            onClick={action.onClick}
          >
            {action.label}
          </button>
        );
      }
    }

    const content = (
      <div
        className={clsx('flex-1', {
          'md:mt-0.5': !!action,
        })}
      >
        {header && <div className="mb-1 text-lg font-semibold">{header}</div>}
        {children}
      </div>
    );

    let renderedIcon: React.ReactNode;
    if (icon === 'auto') {
      if (error || type === 'error') {
        renderedIcon = (
          <ExclamationTriangleIcon className="h-7 w-7 text-red-500" />
        );
      } else if (warning || type === 'warning') {
        renderedIcon = <ClockIcon className="h-7 w-7 text-yellow-500" />;
      } else if (success || type === 'success') {
        renderedIcon = <CheckCircleIcon className="h-7 w-7 text-green-500" />;
      } else if (info || type === 'info') {
        renderedIcon = (
          <ExclamationCircleIcon className="h-7 w-7 text-blue-500" />
        );
      } else {
        renderedIcon = <ExclamationCircleIcon className="h-7 w-7" />;
      }
    } else {
      renderedIcon = icon;
    }

    return (
      <div {...props} className={containerCx} ref={ref}>
        {renderedIcon ? (
          <div className="flex flex-1 space-x-4">
            <div className={header ? 'mt-0.5' : ''}>{renderedIcon}</div>
            {content}
          </div>
        ) : (
          content
        )}

        {action ? <div>{renderedAction}</div> : null}
      </div>
    );
  },
);
