import { fetcher } from '../fetcher';

export const fetchNewAccessToken = (refreshToken: string, clientId: string) =>
  fetcher<{
    access_token: string;
    refresh_token: string;
  }>('/iam/v1/auth/token', {
    body: {
      grant_type: 'refresh_token',
      token: refreshToken,
      client_id: clientId,
    },
    method: 'post',
    authHeader: false,
    skipRefresh: true,
  });
