import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/store/rootReducer';

export interface UiSliceState {
  filterModalOpen: boolean;
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    filterModalOpen: false,
  },
  reducers: {
    setFilterModalOpen: (state, action: PayloadAction<{ open: boolean }>) => {
      state.filterModalOpen = action.payload.open;
    },
  },
});

export const { setFilterModalOpen } = uiSlice.actions;

export const isFilterModalOpen = (state: RootState) => state.ui.filterModalOpen;

export default uiSlice.reducer;
