import React, { useContext } from 'react';
import { KnownCookies } from 'common-types';

export const ServerSideCookiesContext = React.createContext<KnownCookies>({});

export function useServerSideCookies() {
  const context = useContext(ServerSideCookiesContext);

  if (!context) {
    throw new Error(
      '`useServerSideCookies` used outside of ServerSideCookiesContext context',
    );
  }

  return context;
}
