import { universalSetCookie } from 'common-nextjs';
import { KnownCookies } from 'common-types';
import * as cookie from 'cookie';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { destroyCookie, parseCookies } from 'nookies';

export function getKnownCookie<T extends keyof KnownCookies>(
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
  name: T,
): KnownCookies[T] {
  const cookies = parseCookies(ctx) as KnownCookies;
  return cookies[name];
}

export function setKnownCookie<T extends keyof KnownCookies>(
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
  name: T,
  value: NonNullable<KnownCookies[T]>,
  options?: cookie.CookieSerializeOptions,
): void {
  universalSetCookie(ctx, name, value, options);
}

export function removeKnownCookie<T extends keyof KnownCookies>(
  ctx: NextPageContext | GetServerSidePropsContext | null | undefined,
  name: T,
  options?: cookie.CookieSerializeOptions,
): void {
  destroyCookie(ctx, name, {
    path: '/',
    ...options,
  });
}
