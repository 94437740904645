import { useQuery } from '@tanstack/react-query';
import { fetchUserConfiguration } from '~/api/user';
import { minutes5inMS } from '~/constants/times';
import { useSession } from '~/contexts/SessionContext';
import { getKnownCookie, setKnownCookie } from '~/hooks/cookies';
import { RailsUserConfiguration } from '~/typings/services/rails/session';

// Set a cookie when the user's purchase count is greater than 0.
// We use this to prevent PayPal from sending anonymous users offers, whom
// we know have purchased before.
function setPurchaseCountCookie(data: RailsUserConfiguration) {
  if (getKnownCookie(undefined, 'sls_user_haspc') !== '1') {
    if (data?.properties?.purchase_count > 0) {
      setKnownCookie(undefined, 'sls_user_haspc', '1');
    }
  }
}

export default function useUserConfiguration() {
  const { loggedIn } = useSession();
  return useQuery(['user-configuration'], fetchUserConfiguration, {
    staleTime: minutes5inMS,
    onSuccess: setPurchaseCountCookie,
    enabled: loggedIn,
  });
}
