import { GetServerSidePropsContext, NextPageContext } from 'next';
import { UAParser } from 'ua-parser-js';
import { AppContext } from 'next/app';
import { DeviceData } from './types';

/**
 * @param userAgent If not provided, `window.navigator.userAgent` will be used.
 */
export function parseUserAgent(userAgent?: string | null) {
  try {
    return new UAParser(userAgent || undefined);
  } catch (e) {
    return null;
  }
}

export function getParsedUserAgent(appCtx: AppContext): DeviceData {
  const ua = parseUserAgent(appCtx.ctx.req?.headers?.['user-agent']);

  return evaluateParsedUserAgent(ua);
}

export function evaluateParsedUserAgent(
  parsedUserAgent: UAParser | null,
): DeviceData {
  let device: DeviceData['device'];
  let os: string | undefined;

  try {
    os = parsedUserAgent?.getOS().name;
    device =
      parsedUserAgent?.getDevice()?.type === 'mobile' ? 'mobile' : 'desktop';
  } catch (e) {
    device = 'mobile';
  }

  return { device, os };
}

export function isMobileRequest(
  ctx: NextPageContext | GetServerSidePropsContext,
): boolean {
  const ua = parseUserAgent(ctx.req?.headers?.['user-agent']);

  // Default to "true" for mobile
  return ua?.getDevice()?.type === 'mobile' ?? true;
}
