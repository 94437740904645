import { useEvent } from 'react-use';

export interface CustomEvents {
  sls_logout: null;
  sls_login: null;
  sls_app_dir_refresh_from_legacy: null;
}

export function useCustomEvent<TEventName extends keyof CustomEvents>(
  event: TEventName,
  handler: (event: CustomEvents[TEventName]) => void,
) {
  return useEvent(event, handler);
}

// ----- Events:

export function dispatchLogoutEvent() {
  return window.dispatchEvent(
    new CustomEvent<CustomEvents['sls_logout']>('sls_logout'),
  );
}

export function dispatchLoginEvent() {
  return window.dispatchEvent(
    new CustomEvent<CustomEvents['sls_login']>('sls_login'),
  );
}

export function dispatchAppDirRefreshFromLegacyEvent() {
  return window.dispatchEvent(
    new CustomEvent<CustomEvents['sls_app_dir_refresh_from_legacy']>(
      'sls_app_dir_refresh_from_legacy',
    ),
  );
}
