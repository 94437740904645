import { Session } from '../Session';
import { fetcher, FetcherEndpoint, FetcherOptions } from './fetcher';

export async function fetcherSession<R>(
  session: Session | undefined,
  endpoint: FetcherEndpoint,
  config?: FetcherOptions | null,
): Promise<R> {
  // @ts-ignore
  return fetcher.bind(session)(endpoint, config);
}
