'use client';

import produce from 'immer';
import React, { useState } from 'react';
import { CSSProp } from 'styled-components';
import { createContainer } from 'unstated-next';
import { Modals, ModalType } from '~/components/Modal/ModalsGroup';
import { getSentry } from '~/services/sentry/wrapper';

export interface ModalsValue {
  closeModal: (type?: number | ModalType) => void;
  modals: ModalDescriptor[];
  // openModal: (type: ModalType, props?: Record<string, any>) => void;
  // setModal: (type: ModalType, props: Record<string, any>) => void;
}

export interface ModalStyleProps {
  modalOverlayCss?: CSSProp;
  modalBodyCss?: CSSProp;
  modalWidth?: number;
  hideCloseButton?: boolean;
}

export interface ModalDescriptor {
  id: number;
  open: boolean;
  type: ModalType;
  props:
    | undefined
    | Record<
        | 'modalOverlayCss'
        | 'modalBodyCss'
        | 'hideCloseButton'
        | 'overrideAnimationClasses'
        | string,
        any
      >;
  component?: (onRequestClose: () => void) => React.ReactNode;
}

export interface ModalsState {
  modals: ModalDescriptor[];
}

type ModalPropsArgument<T extends ModalType> = Modals[T] & ModalStyleProps;

function useModalsHook(initialState?: ModalsState) {
  const [modals, setModals] = useState<ModalDescriptor[]>(
    initialState?.modals ?? [],
  );

  function openModal<Type extends ModalType>(
    type: Type,
    props: ModalPropsArgument<Type>,
    component?: ModalDescriptor['component'],
  ) {
    window.zE?.('webWidget', 'hide');
    getSentry()?.addBreadcrumb({
      message: `opened modal '${type}'`,
      data: props,
    });
    setModals(
      produce(state => {
        state.push({
          component,
          type,
          props: props ?? {},
          id: Date.now(),
          open: true,
        });
      }),
    );
  }

  function closeModal(typeOrId?: number | ModalType) {
    getSentry()?.addBreadcrumb({ message: `closed modal '${typeOrId}'` });
    window.zE?.('webWidget', 'show');
    setModals(
      produce(state => {
        if (typeOrId) {
          let index;
          if (typeof typeOrId === 'number') {
            index = state.findIndex(m => m.id === typeOrId);
          } else {
            index = state.findIndex(m => m.type === typeOrId);
          }

          if (index >= 0) {
            state[index].open = false;
          }
        } else {
          state[state.length - 1].open = false;
        }
      }),
    );
  }

  function setModal<T extends ModalType>(
    type: T,
    props: Partial<ModalPropsArgument<T>>,
  ) {
    setModals(state => state.map(m => (m.type === type ? { ...m, props } : m)));
  }

  function deleteModal<T extends ModalType>(id: number) {
    setModals(state => state.filter(m => m.id !== id));
  }

  return {
    closeModal,
    modals,
    openModal,
    deleteModal,
    setModal,
  };
}

export const ModalsContainer = createContainer(useModalsHook);

// export function useModal(type: ModalType) {
//   const container = ModalsContainer.useContainer();
//
//   return useMemo(() => container.modals.find(m => m.type === type), [
//     container,
//     container.modals,
//     type,
//   ]);
// }

export function useModals() {
  return ModalsContainer.useContainer();
}
