import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { RailsItem } from '~/typings/services/rails/item';

export const startInitializationPhase = createAction<number | undefined>(
  'listFlow/startInitializationPhase',
);

export const initializeFromItem = createAction<RailsItem>(
  'listFlow/initializeFromItem',
);

export const finishInitializingItem = createAction(
  'listFlow/finishInitializingItem',
);
