import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initializeFromItem } from '~/store/listFlow/initializationActions';
import { RootState } from '~/store/rootReducer';

export interface ListFlowPricingState {
  acceptOffers: boolean;
  listPrice?: number;
  retailPrice?: number;

  isAuction?: boolean;
  auctionStartDate?: string | number;

  autoPriceDrop: {
    enabled: boolean;
    minimum: number;
  };
  minimumOffer: {
    enabled: boolean;
    amount: number;
    default: number | null;
  };
}

export const listFlowPricingInitialState: ListFlowPricingState = {
  acceptOffers: true,
  autoPriceDrop: {
    enabled: false,
    minimum: 0,
  },
  minimumOffer: {
    enabled: false,
    amount: 0,
    default: 0, // set from your account settings
  },
};

const listFlowPricingSlice = createSlice({
  initialState: listFlowPricingInitialState,
  name: 'listFlow/pricing',
  reducers: {
    changePricing(
      state,
      action: PayloadAction<{
        field: string;
        value?: number;
      }>,
    ) {
      const { field, value } = action.payload;
      state[field] = value;
    },
    changedAutoPriceDrop(
      state,
      action: PayloadAction<{ enabled?: boolean; newMinimum?: number }>,
    ) {
      if (action.payload.enabled != null) {
        state.autoPriceDrop.enabled = action.payload.enabled;
      }
      if (action.payload.newMinimum != null) {
        state.autoPriceDrop.minimum = action.payload.newMinimum;
      }

      if (
        state.autoPriceDrop.enabled &&
        !state.autoPriceDrop.minimum &&
        state.listPrice
      ) {
        state.autoPriceDrop.minimum = Math.round(state.listPrice / 2);
      }
    },
    toggleAcceptsOffers(state, action: PayloadAction<{ enabled: boolean }>) {
      state.acceptOffers = action.payload.enabled;
    },
    toggleAuction(state, action: PayloadAction<{ enabled: boolean }>) {
      const enabled = action.payload.enabled;
      state.isAuction = enabled;

      if (enabled) {
        state.acceptOffers = true;
      } else {
        state.auctionStartDate = undefined;
      }
    },
    changedAuctionStartDate(
      state,
      action: PayloadAction<{ newDate: string | number | null }>,
    ) {
      const { newDate } = action.payload;
      state.auctionStartDate = newDate ?? undefined;
    },
    changedMinimumOfferThreshold(
      state,
      action: PayloadAction<{ enabled?: boolean; amount?: number }>,
    ) {
      state.minimumOffer.enabled =
        action.payload.enabled === false
          ? false
          : action.payload.enabled || state.minimumOffer.enabled;
      state.minimumOffer.amount =
        action.payload.amount || state.minimumOffer.amount;
    },
  },
  extraReducers: builder => {
    //! HEY, if you're editing this reducer, check if you need to edit `getListFlowSubmittableItem`
    builder.addCase(initializeFromItem, (state, action) => {
      const item = action.payload;
      const newState: ListFlowPricingState = {
        listPrice: item.list_price,
        retailPrice: item.price_retail,
        acceptOffers: item.accepts_offers != null ? item.accepts_offers : true,
        isAuction: item.auction,
        auctionStartDate: item.auction_started_at
          ? new Date(item.auction_started_at).toISOString()
          : undefined,
        autoPriceDrop: {
          enabled: !!item.auto_price_drop,
          minimum: item.auto_price_drop_min_price || 0,
        },
        minimumOffer: {
          enabled: !!item.minimum_offer_threshold,
          amount: item.minimum_offer_threshold
            ? item.minimum_offer_threshold * 100
            : 0,
          default: 0,
        },
      };

      return newState;
    });
  },
});

export const {
  changedAuctionStartDate,
  changedMinimumOfferThreshold,
  changePricing,
  changedAutoPriceDrop,
  toggleAcceptsOffers,
  toggleAuction,
} = listFlowPricingSlice.actions;

const listFlowPricingReducer = listFlowPricingSlice.reducer;

export default listFlowPricingReducer;

export const getListFlowPricing = (state: RootState) => state.listFlow.pricing;
export const getListFlowAutoPriceDrop = (state: RootState) =>
  state.listFlow.pricing.autoPriceDrop;
export const getListFlowAcceptsOffers = (state: RootState) =>
  state.listFlow.pricing.acceptOffers;

export const getListFlowMinimumOffer = (state: RootState) =>
  state.listFlow.pricing.minimumOffer;
