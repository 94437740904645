import Cookies from 'js-cookie';

export default function getUTMsFromCookies() {
  const cookies = Cookies.get();

  const cookieKeys = Object.keys(cookies).filter(c => c.startsWith('utm_'));

  if (cookieKeys.length === 0) {
    return false;
  }

  return cookieKeys.reduce<Record<string, string>>(
    (prev, cur) => ({ ...prev, [cur]: cookies[cur] }),
    {},
  );
}
