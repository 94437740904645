import {
  RailsCategory,
  RailsDetail,
  RailsItemModel,
} from '~/typings/services/rails/item';

function childToString(child: RailsDetail | RailsCategory): string | undefined {
  // Skip over title names that are simply empty strings.
  if (child.title_name === '') {
    return;
  }

  return child.title_name || child.name;
}

export default function generateItemTitle(
  details: Record<
    string,
    { detail: RailsDetail; selected?: null | RailsDetail | 'variations' }
  >,
  category?: RailsCategory,
  model?: RailsItemModel,
): string {
  if (!details) return '';

  const modifiedDetails = { ...details };

  if (model) {
    modifiedDetails.model = {
      // @ts-ignore
      detail: {
        name: 'Model',
        slug: 'model',
        // Model is hardcoded as position 100, which gives us flexibility on the backend
        title_order: 100,
        children: [],
      },
      // @ts-ignore
      selected: model,
    };
  }

  if (category) {
    modifiedDetails.category = {
      // @ts-ignore
      detail: {
        name: 'Category',
        slug: 'category',
        title_order:
          details.category?.detail.title_order ?? category?.title_order ?? 101,
        children: [],
      },
      // @ts-ignore
      selected: category,
    };
  }

  const values = Object
    // Take the values
    .values(modifiedDetails)

    // Only take the ones that have a title order
    .filter(d => !!d.detail.title_order)

    // For the ones that have a title order, sort them ascending by their order
    .sort((a, b) => a.detail.title_order! - b.detail.title_order!);

  // Make sure there is at least one entry
  if (values.length === 0) return '';

  // Make the object we're going to end with
  const out: string[] = [];

  for (let i = 0; i < values.length; i++) {
    const value = values[i];

    if (value.detail.slug === 'category') {
      if (!modifiedDetails.category || !category) {
        continue;
      }

      const categoryTitle = childToString(category);

      if (!categoryTitle) {
        continue;
      }

      out.push(categoryTitle);
      continue;
    }

    // Somehow, it has no children
    if (!value.selected || value.selected === 'variations') {
      continue;
    }

    const childNames = childToString(value.selected);

    // If there were no children to add, just skip over it
    if (!childNames) {
      continue;
    }

    out.push(childNames);
  }

  return out.join(' ').trim();
}
