'use client';

import { getEnv } from 'common-nextjs';
import { fetcher } from 'fetcher-session';
import { qsParse } from 'utils';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';

export default function fireAnalytics<P = Record<string, any>>(
  name: string,
  src?: AnalyticsSource | string,
  params?: P,
) {
  const urlSrc = qsParse(window.location.search).src;

  const combinedSrc = src || urlSrc;

  if (getEnv() !== 'production') {
    console.groupCollapsed(name);
    if (!combinedSrc) {
      console.warn('No Source');
    } else {
      console.log(
        'Source:',
        combinedSrc,
        !src && urlSrc ? '(using src from URL)' : '',
      );
    }
    console.log('Params:', params);
    console.groupEnd();
  }

  window.amplitude?.getInstance()?.logEvent(name, {
    ...params,
    src: combinedSrc || '',
  });

  fetcher(
    ({ uuid }) => (uuid ? '/platform-tools/analytics/v1/track/event' : false),
    {
      body: {
        event_type: name,
        properties: params || {},
      },
      method: 'post',
    },
  ).catch(() => null);
}
