import { getEnv } from 'common-nextjs';

export default function fireGtagEvent(...args: any[]) {
  if (getEnv() !== 'production') {
    console.groupCollapsed('GTAG Event');
    args.forEach(arg => console.log(arg));
    console.groupEnd();
  }

  if (window.gtag) {
    window.gtag(...args);
  }
}
