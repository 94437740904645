import { Action, configureStore, Store, ThunkAction } from '@reduxjs/toolkit';
import { getEnv } from 'common-nextjs';
import { Context, createWrapper } from 'next-redux-wrapper';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { addNewPhoto, cropImage } from '~/store/listFlow/slices/photos';
import principalReducer, { RootState } from '~/store/rootReducer';

export function createStore(initialState?: RootState) {
  const store = configureStore({
    preloadedState: initialState,
    devTools: getEnv() !== 'production',
    reducer: principalReducer as (s?: RootState, a?: any) => RootState,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: [addNewPhoto.type, cropImage.type],
        },
      }),
      // loggerMiddleware,
    ],
  });

  // @ts-ignore
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    // @ts-ignore
    module.hot.accept('./rootReducer', () =>
      store.replaceReducer(principalReducer),
    );
  }

  return store;
}

type AppStore = ReturnType<typeof createStore>;
// export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type ThunkConfig<
  RejectValue = unknown,
  RejectedMeta = unknown,
  FulfilledMeta = unknown,
> = {
  state: RootState;
  dispatch: AppDispatch;
  extra: unknown;
  rejectValue: RejectValue;
  serializedErrorType: unknown;
  pendingMeta: unknown;
  fulfilledMeta: FulfilledMeta;
  rejectedMeta: RejectedMeta;
};

const makeStore = (context: Context) => {
  const store = createStore();

  return store;
};

export interface RootStore extends Store<RootState> {
  dispatch: AppDispatch;
}

export const reduxWrapper = createWrapper<RootStore>(makeStore);
