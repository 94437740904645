import fireAnalytics from '~/services/analytics/fireAnalytics';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { SocialAuthType } from '~/typings/services/iam/auth';
import { getEnv } from 'common-nextjs';
import getUTMsFromCookies from '~/services/analytics/getUTMsFromCookies';
import { fetchReferralCodeByName } from '~/api/user';
import fireGAEvent from '~/services/analytics/fireGAEvent';
import fireGtagEvent from '~/services/analytics/fireGtagEvent';
import fireFacebookEvent from '~/services/analytics/fireFacebookEvent';

export const visitedSignIn = () => fireAnalytics('Visited Sign In');

export const visitedJoin = ({
  src,
  test_src,
}: {
  src?: AnalyticsSource;
  test_src?: string;
} = {}) => fireAnalytics('Visited Join', src, { test_src });

export const visitedEmailJoin = (src?: AnalyticsSource) =>
  fireAnalytics('Visited Email Join', src);

export const visitedResetPassword = () =>
  fireAnalytics('Visited Reset Password');

export const fireJoinedEvent = async (
  provider: 'Login and Password' | SocialAuthType | undefined,
  referralCode?: string,
  src?: AnalyticsSource,
) => {
  fireGtagEvent('event', 'conversion', {
    send_to: `${getEnv('ADWORDS_KEY')}/QaBzCL_8s5oBEMe5_OUC`,
  });

  fireFacebookEvent('track', 'CompleteRegistration');

  fireGAEvent('send', 'event', 'User', 'Joined');

  fireAnalytics('Joined', src, {
    ...getUTMsFromCookies(),
    authorisation_provider: provider, // I know it's spelled wrong...
    referral_code: referralCode,
    referral_amount: referralCode
      ? (await fetchReferralCodeByName(referralCode))?.referral_reward
      : undefined,
  });
};

export const fireSignedInEvent = () => fireAnalytics('Signed In');

export const fireSignedOutEvent = () => fireAnalytics('Signed Out');

export const initiatedSocialAuth = (type: 'facebook', src?: AnalyticsSource) =>
  fireAnalytics('Initiated Social Auth', src, { type });

export const visitedRoleOnboarding = () => fireAnalytics('Visited Role');

export const visitedFavoriteSportsOnboarding = () =>
  fireAnalytics('Visited Favorite Sports');

export const visitedRegisterFinish = () =>
  fireAnalytics('Visited Register Finish');

export const visitedProfileOnboarding = () =>
  fireAnalytics('Visited Profile Onboarding');

export const selectedRole = (roles: string[]) =>
  fireAnalytics('Selected Role', null, {
    role: roles.map(r => r.toUpperCase()),
  });
