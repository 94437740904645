import { useQuery } from '@tanstack/react-query';
import { addDays, isWithinInterval } from 'date-fns';
import { createContainer, useContainer } from 'unstated-next';
import { fetchSales } from '~/api/configuration';
import { useSession } from '~/contexts/SessionContext';
import useKnownCookie from '~/hooks/useKnownCookie';
import { useV2Session } from '~/hooks/useV1Session';
import { minutes5inMS } from '../constants/times';

export type SaleInitialState = {
  dismissed?: boolean;
};

function useSaleContainer({ dismissed }: SaleInitialState = {}) {
  const { data: sales } = useQuery(['sales'], fetchSales, {
    staleTime: minutes5inMS,
  });
  const [dismissedCookie, setDismissedCookie] =
    useKnownCookie('dismissed_sale');

  const { loggedIn, user } = useSession();

  const sale = sales?.[0];

  let showBanner = false;

  const isBuySide = sale
    ? isWithinInterval(Date.now(), {
        start: new Date(sale.started_at),
        end: new Date(sale.ended_at),
      })
    : false;
  const isSellSide = sale
    ? isWithinInterval(Date.now(), {
        start: new Date(sale.opened_at),
        end: new Date(sale.ended_at),
      })
    : false;

  const { data: v2Session } = useV2Session(isSellSide);

  if (!sale) {
    return {
      sale,
      dismiss: () => null,
      showBanner: false,
    };
  }

  function dismiss() {
    setDismissedCookie('1', {
      expires: addDays(Date.now(), 3),
      path: '/',
    });
  }

  if (dismissedCookie || dismissed) {
    showBanner = false;
  } else if (isBuySide) {
    showBanner = true;
  } else if (isSellSide) {
    if (loggedIn && user) {
      showBanner = !!v2Session?.actions.includes('view_sale_banner');
    }
  }

  return {
    dismiss,
    isBuySide,
    isSellSide,
    sale,
    showBanner,
  };
}

const SaleContainer = createContainer(useSaleContainer);

export function useSale() {
  return useContainer(SaleContainer);
}

export default SaleContainer;
