import { fetcher, getBaseUrl, makeFetcher } from 'fetcher-session';
import {
  RailsConfigEmblem,
  RailsSortConfig,
  RailsWebConfiguration,
} from '~/typings/services/rails/configuration';
import { FacetContext } from '~/typings/services/rails/facets';
import { RailsSale } from '~/typings/services/rails/sales';

export const fetchWebConfiguration = () =>
  fetcher<RailsWebConfiguration>('/v1/configs/web', {
    params: { platform: 'web' },
    baseUrl: getBaseUrl('edge'),
  });

export const fetchSortConfig = (context?: FacetContext) =>
  fetcher<RailsSortConfig>('/v1/config/item_sort', {
    params: {
      context,
      platform: 'web',
    },
    baseUrl: getBaseUrl('edge'),
    authHeader: false,
  });

export const fetchSales = makeFetcher<RailsSale[]>(() => [
  '/v1/sales',
  {
    baseUrl: getBaseUrl('edge'),
  },
]);

export const fetchEmblemsConfiguration = () =>
  fetcher<RailsConfigEmblem[]>('/v1/config/emblems', {
    baseUrl: getBaseUrl('edge'),
  });
