import qs from 'qs';

export function qsParse<Q extends Record<string, any>>(
  query?: string | URLSearchParams | null,
  options: qs.IParseOptions = {},
): Q {
  if (!query) {
    // @ts-ignore
    return {};
  }

  let actualQuery: string;
  if (typeof query === 'string') {
    if (query[0] === '?') {
      actualQuery = query.slice(1);
    } else {
      actualQuery = query;
    }
  } else {
    actualQuery = query.toString();
  }

  return qs.parse(actualQuery, {
    // @ts-ignore
    arrayFormat: 'brackets',
    skipNulls: true,
    ...options,
  }) as Q;
}
