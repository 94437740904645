export default function identifySentryUser(user: {
  username: string;
  external_id: string;
  email?: string;
}) {
  return {
    email: user.email,
    id: user.external_id,
    username: user.username,
  };
}
