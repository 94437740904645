'use client';

import React from 'react';

interface Props {}

const ImpactTracker: React.FC<Props> = () => (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://d.impactradius-event.com/A2401526-017e-4c8d-92bb-c12c27d322271.js','script','ire',document,window);`,
    }}
    async
  />
);

export default ImpactTracker;
