import Script from 'next/script';

export default function AttentiveScript({}: {}) {
  return (
    <Script
      src="https://cdn.attn.tv/sdlnswp/dtag.js"
      strategy="afterInteractive"
      id="attentive"
    />
  );
}
