import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import ZenDeskWidgetScript from '~/components/ThirdPartyScripts/ZenDeskWidgetScript';
import { useSession } from '~/contexts/SessionContext';
import { useSwapPopout } from '~/contexts/SwapPopoutContext';
import useUserConfiguration from '~/hooks/useUserConfiguration';

const AutomaticPopupModal = dynamic(
  () => import('~/components/LoginRegister/AutomaticPopupModal'),
);
const ModalsGroup = dynamic(() => import('~/components/Modal/ModalsGroup'));
// const SwapPopoutContainer = dynamic(
//   () => import('~/components/SwapPopout/SwapPopoutContainer'),
// );
const SwapPopoutContainerV2 = dynamic(
  () => import('~/components/SwapPopout/SwapPopoutContainerV2'),
);

interface Props {}

const ClientSideAppLoads: React.FC<Props> = () => {
  const [mounted, setMounted] = useState(false);
  const { enabled } = useSwapPopout();

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <>
      <ZenDeskWidget />
      <ModalsGroup />
      <AutomaticPopupModal />
      {/*{enabled && <SwapPopoutContainer />}*/}
      {enabled && <SwapPopoutContainerV2 />}
    </>
  );
};

function ZenDeskWidget() {
  const { loggedIn } = useSession();
  const { data: userConfiguration } = useUserConfiguration();
  const pathname = useRouter().pathname;

  return (
    <ZenDeskWidgetScript
      userConfiguration={userConfiguration}
      route={pathname}
      loggedIn={loggedIn}
    />
  );
}

export default ClientSideAppLoads;
